import React, { useState, useEffect } from "react";

import Avatar from "./Avatar";
import { getAgentInfo, checkOdreDemande } from "./Header";

import AgentService from "../services/agent.service";
import AuthService from "../services/auth.service";
import StatutDemandeService from "../services/statutdemande.service";
import DemandeService from "../services/demande.service";

import { Form, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";

import fr from "date-fns/locale/fr";
const { format } = require("date-fns");

export const AgentInfo = (props) => {
  const id = props.id
  const [agent, setAgent] = useState("");

  useEffect(() => {
    getAgentInfo(id)
      .then((agent) => {
        //console.log(agent);
        setAgent(agent);
      })
      .catch((err) => {
        console.warn(err.message);
      });
  }, []);

  useEffect(() => {
    if (agent != "" && props.sendingAgentdata != undefined && agent.hasOwnProperty("superieur_id")) {
      props.sendingAgentdata(agent.superieur_id)
    }
  }, [agent])

  /*useEffect(() => {
    if (agent != "") {
      sendingAgentdata(agent.superieur_id)
    }
  }, [agent])*/

  return (
    <>
      {agent != "" && (
        <div className="action-label">
          <h2 className="table-avatar mr-3">
            <a href="" className="avatar avatar-xs">
              <Avatar url={agent.avatar} />
            </a>
            <a href="#">{agent.prenom}</a>
          </h2>
        </div>
      )}
    </>
  );
};

const StatutStaffInfo = (props) => {
  const id = props.idUser;
  const [cadres, setCadres] = useState("");
  const [agent, setAgent] = useState("");

  const getCadres = () => {
    AgentService.getAgentCadres()
      .then((data) => {
        setCadres(data);
      })
      .catch((error) => {
        console.warn(error.message);
      });
  };

  useEffect(() => {
    getCadres();
  }, []);

  useEffect(() => {
    if (cadres) {
      //console.log(cadres);
      let agent = cadres.find((o) => o.id_utilisateur == id);
      if (agent) {
        getAgentInfo(agent.id_agent)
          .then((agent) => {
            //console.log(agent);
            setAgent(agent);
          })
          .catch((err) => {
            console.warn(err.message);
          });
      }
    }
  }, [cadres]);

  return (
    <>
      {agent != "" && (
        <>
          <a href="" className="avatar avatar-xs mt-1">
            <Avatar url={agent.avatar} />
          </a>
          {/*<a href="#">{agent.abbr_fonction}</a>*/}
        </>
      )}
    </>
  );
};

const printStatut = (statut) => {
  switch (statut) {
    case 0:
      return (
        <>
          <i className="fa fa-dot-circle-o text-purple"></i> En attente{" "}
        </>
      );
    case 1:
      return (
        <>
          <i className="fa fa-dot-circle-o text-success"></i> Approuvée
        </>
      );
    default:
      return "";
  }
};

const StatutItem = (props) => {
  return (
    <>
      {props.statut.length > 0 &&
        props.statut.map((element) => {
          return (
            <Row className="table-avatar mb-3">
              <Col xs={2}>
                <StatutStaffInfo idUser={element.id_utilisateur} />
              </Col>
              <Col xs={10} className="action-label">
                <a className="btn btn-white btn-sm btn-rounded" href="">
                  {printStatut(element.statut)}
                </a>
              </Col>
            </Row>
          );
        })}
    </>
  );
};

//props: demande => demande à afficher, idDemande => id demande à mettre en évidence (venant du clic sur notification peut-être)
const DemandeItem = (props) => {
  const demande = props.demande;
  const idDemandeToValidate = props.idDemandeToValidate;
  //console.log(idDemandeToValidate);
  const [title, setTitle] = useState(0);
  const [ordreUserconnected, setOrdreUserconnected] = useState(0);
  const [nombreUtilisateurRequis, setNombreUtilisateurRequis] = useState(0);
  const [idstatutToEdit, setIdstatutToEdit] = useState(0);
  const [id, setId] = useState(0);
  const userId = props.userId;

  useEffect(() => {
    if (userId) {
      //console.log(demande);
      if (demande) {
        getInformationUserConnected();
      }
      if (idDemandeToValidate) {
        setId(idDemandeToValidate)
      }
    }
  }, []);

  // récupérer information pour cet utilisateur connecté
  const getInformationUserConnected = () => {
    let choixUserConnected = demande.statut.find(
      (o) => o.id_utilisateur == userId && o.id_demande == demande.id_demande
    );
    //console.log(choixUserConnected);
    //console.log(demande);
    if (choixUserConnected) {
      if (choixUserConnected.hasOwnProperty("statut")) {
        setTitle(choixUserConnected.statut);
      }
      if (choixUserConnected.hasOwnProperty("ordre")) {
        setOrdreUserconnected(choixUserConnected.ordre);
      }
      setNombreUtilisateurRequis(demande.statut.length);
      if (choixUserConnected.hasOwnProperty("id_statut")) {
        setIdstatutToEdit(choixUserConnected.id_statut);
      }
    }
  };

  //vérification avant d'approuver une demande: prédécesseurs ont déjà validé, si moi dernier dans l'ordre modifier colonne approbation de la table Demande
  const verification = (idDemande) => {
    //j'ai pas encore approuvé auparavant?

    //suis-je le dernier à approuver?
    console.log("ordre utilisateur connecté" + ordreUserconnected);
    console.log("nombre user à valider" + nombreUtilisateurRequis);
    console.log("statut demande à valider" + idstatutToEdit);
  };

  const approuverDemande = (idDemande) => {
    try {
      let nonvalide = false;
      console.log("demande à approuver" + idDemande);
      console.log("statut demande à approuver" + idstatutToEdit);
      //si ordre user connected = 1 et statut = 0 => demande a besoin d'être validé par cet user
      if (ordreUserconnected == 1) {
        console.log("je suis le premier à valider");
        StatutDemandeService.update(idstatutToEdit).then((value) =>
          console.log(value)
        );
      } else {
        //rechercher dans statuts les demandes dont ordre < à user connecté
        let statutsInf = demande.statut.filter(
          (element) => element.ordre < ordreUserconnected
        );
        console.log("ireto avy ireo statut_demande inférieur à moi");
        console.log(statutsInf);
        if (statutsInf.length > 0) {
          //d'autres utilisateurs doivent valider cette demande avant moi
          //check si ces utilisateurs ont déjà effectué cette validation
          if (
            statutsInf.every((v) => {
              if (v.statut == 0) {
                //non validé
                return false;
              }
              // Make sure you return true. If you don't return a value, `every()` will stop.
              return true;
            })
          ) {
            //ils ont déjà effectué leurs validations
            nonvalide = true;
            console.log(
              "ils ont déjà effectué leurs validations, à mon tour maintenant"
            );
            //return nonvalide

            StatutDemandeService.update(idstatutToEdit).then((value) =>
              console.log(value)
            );

            //je suis le dernier à valider
            if (ordreUserconnected == nombreUtilisateurRequis) {
              //modifier approbation demande en 1
              DemandeService.approuverDemande(idDemande).then((value) =>
                console.log(value)
              );
            }
          }
        }
      }
    } catch (error) {
      console.warn(error.message);
    }
  };

  const handleSelect = (idDemande, statut) => {
    //console.log(title);
    switch (statut) {
      case 0: //approuver
        setTitle(1);
        console.log("approuver");
        approuverDemande(idDemande);
        window.location.reload();
        break;
      case 1: //désapprouver
        setTitle(0);
        console.log("desapprouver");
        break;
      default:
        console.log("default select");
    }
  };
  //console.log(demande);
  return (
    <>
      {demande && (
        <tr style={{ backgroundColor: id == demande.id_demande && "lightyellow" }}>
          <td>
            <AgentInfo id={demande.id_agent} />
          </td>
          <td>
            {format(new Date(demande.date_demande), "d MMMM yyyy", {
              locale: fr,
            })}
          </td>
          <td>
            {format(new Date(demande.debut_conge), "d MMMM yyyy", {
              locale: fr,
            })}
          </td>
          <td>
            {format(new Date(demande.fin_conge), "d MMMM yyyy", { locale: fr })}
          </td>
          <td>
            {demande.nb_jours} {demande.nb_jours > 1 ? "jours" : "jour"}
          </td>
          <td>
            {demande.solde_avant}
            {demande.solde_avant > 1 ? " jours" : " jour"}
          </td>
          <td>
            {demande.solde_apres}
            {demande.solde_apres > 1 ? " jours" : " jour"}
          </td>
          <td>
            <StatutItem statut={demande.statut} userId={props.userId} />
          </td>
          <td className="text-center action-label">
            <Form>
              <Form.Group controlId="formGridPassword">
                <DropdownButton
                  id="dropdown-button-dark-example2"
                  title={printStatut(title)}
                  bsPrefix="mt-2 btn btn-white btn-sm btn-rounded"
                  align="end"
                  disabled={title == 1 && true}
                >
                  <Dropdown.Item
                    eventKey="1"
                    bsPrefix="dropdown-item"
                    onClick={() => handleSelect(demande.id_demande, title)}
                  >
                    {title == 0 ? printStatut(1) : printStatut(0)}
                  </Dropdown.Item>
                </DropdownButton>
              </Form.Group>
            </Form>
          </td>
        </tr>
      )}
    </>
  );
};

export default DemandeItem;
