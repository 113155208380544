import React from "react";

const PageHeader = (props) => {
  return (
    <>
      <div className="page-header">
        <div className="row align-items-center">
          <div className="col">
            <h3 className="page-title">{props.title}</h3>
            {/*<ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Tableau de bord</a>
              </li>
              <li className="breadcrumb-item active">Employés</li>
  </ul>*/}
          </div>
          {/*<div className="col-auto float-right ml-auto">
            <a
              href="#"
              className="btn add-btn"
              data-toggle="modal"
              data-target="#add_employee"
            >
              <i className="fa fa-plus"></i> Voir les demandes de congés
            </a>
            <div className="view-icons">
              <a
                href="employees.html"
                className="grid-view btn btn-link active"
              >
                <i className="fa fa-th"></i>
              </a>
              <a href="employees-list.html" className="list-view btn btn-link">
                <i className="fa fa-bars"></i>
              </a>
            </div>
          </div>*/}
        </div>
      </div>
    </>
  );
};

export default PageHeader;
