import axios from 'axios';
import { API_baseUrl } from './config';

//const API_url = "http://localhost:8082/api/criteres/"
const API_url = API_baseUrl + "/criteres/"
//const API_url = "http://localhost:8082/api/criteres/"

// get liste des criteres
const getCriteres = () => {
    return axios.get(API_url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            return error.response.data
        });
}

const exports = {
    getCriteres
}

export default exports