import React, { useEffect, useState } from 'react'
import { Row, Col, Table } from 'react-bootstrap'
import AgentService from '../services/agent.service';

import LigneCalendrierMensuel from './LigneCalendrierMensuel';
import { lastday } from './CalendrierConges';

export const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));

export const getListeEmployes = async () => {
    try {
        let listes = await AgentService.getEmployes()
        return listes
    } catch (error) {
        console.warn(error.message);
        throw error
    }

}

const CalendrierMensuel = (props) => {
    const [employes, setEmployes] = useState([])//tous les agents du PREA
    const [jours, setJours] = useState([])
    const [dernierjour, setDernierJour] = useState(0)
    const lesDemandes = props.conges//les demandes

    useEffect(() => {
        getTousAgents()
        setDernierJour(lastday(props.annee, props.mois - 1))
    }, [props.annee, props.mois])

    useEffect(() => {
        if (dernierjour > 0) {
            setJours(range(1, dernierjour, 1))
        }
    }, [dernierjour])

    const getTousAgents = () => {
        getListeEmployes()
            .then(data => {
                //console.log(data);
                setEmployes(data.filter(function (tous) {
                    //console.log(tous);
                    return tous.superieur_id !== null;
                }).sort((a, b) => b.niveau - a.niveau)) //filtrer liste sans inclure SGP
            })
    }
    //console.log(lesDemandes);

    /*sticky header*/
    /*const mystyle = {
        position: 'sticky',
        top: 0,
        left:0,
        backgroundColor: 'yellow',
        padding: 50,
        fontSize: 20,
        zIndex: 200
    };*/
    return (
        <>
            {/*<div style={mystyle}>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Employee</th>
                            <th>1</th>
                            <th>2</th>
                            <th>3</th>
                            <th>4</th>
                            <th>5</th>
                            <th>6</th>
                            <th>7</th>
                            <th>8</th>
                            <th>9</th>
                            <th>10</th>
                            <th>11</th>
                            <th>12</th>
                            <th>13</th>
                            <th>14</th>
                            <th>15</th>
                            <th>16</th>
                            <th>17</th>
                            <th>18</th>
                            <th>19</th>
                            <th>20</th>
                            <th>22</th>
                            <th>23</th>
                            <th>24</th>
                            <th>25</th>
                            <th>26</th>
                            <th>27</th>
                            <th>28</th>
                            <th>29</th>
                            <th>30</th>
                            <th>31</th>
                        </tr>
                    </thead>
                </table>
            </div>*/}
            <Table
                responsive
                striped
                className="custom-table mt-5 mb-0 nowrap"
            >
                <thead>
                    <tr>
                        <th>Agent</th>
                        {
                            jours && (
                                jours.map((element, index) => (<th key={index}>{element}</th>))
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    {(employes && employes.length > 0) && (
                        employes.map(agent => (
                            <LigneCalendrierMensuel agent={agent} key={agent.id_agent} mois={props.mois} annee={props.annee} jours={jours} listeConges={lesDemandes} />
                        ))
                    )}
                </tbody>
            </Table>
        </>
    )
}

export default CalendrierMensuel