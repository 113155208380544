import React from 'react'
//import avatar from '../assets/img/profiles/avatar-02.jpg';
const cache = {};


function importAll(r) {
    r.keys().forEach((key) => (cache[key] = r(key)));
}
// Note from the docs -> Warning: The arguments passed to require.context must be literals!
//importAll(require.context("../assets/img/profiles", false, /\.(png|jpe?g|svg)$/));
//importAll(require.context(process.env.PUBLIC_URL + "/profiles" , false, /\.(png|jpe?g|svg)$/));

const getMatchingAvatar = (avatar) => {
    let res = "";
    const filtered = Object.keys(cache)
        .filter(module => module.replace("./", "") === avatar)
        .reduce((obj, key) => ({ ...obj, [key]: cache[key] }), {});

    Object.values(filtered).map(module => {
        res = module.default;
    })

    return res;
}

const checkImage = (url) => {
    return /[\/.](gif|jpg|jpeg|tiff|png)$/i.test(url);
}

const Avatar = (props) => {
    const baseUrl = process.env.PUBLIC_URL + '/profiles/'
    const src = checkImage(props.url) ? baseUrl + props.url : baseUrl + 'avatar-02.jpg'
    return (
        <>
            {src && <img src={src} alt="" />}
        </>
    );
}

export default Avatar
