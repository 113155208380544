import React, { useState, useEffect } from 'react'
import { Form, Row, Button, Col, FloatingLabel } from "react-bootstrap";

export const mois = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
//composant permettant de rechercher les jours de congé: nom employé, mois, année
const SearchMoisConge = (props) => {
    //aujourd'hui
    const today = new Date()
    const moisCourant = today.getMonth() + 1 //mois courant
    const anneeCourante = today.getFullYear();//année courante
    const anneeDebut = 2018 //début année pris en compte dans la liste déroulante
    const annee = []
    let j = anneeDebut
    for (let i = 0; j <= anneeCourante; i++) {
        annee.push(j)
        j++
    }
    //console.log(annee); //année à partir de 2018-année courante

    const initialState = {
        nom: "",
        mois: moisCourant,
        annee: anneeCourante
    }

    const [state, setState] = useState(initialState)

    // réinitialise les filtres de recherche
    const effacerFiltres = (e) => {
        e.preventDefault();
        //console.log("submitted");
        setState(initialState);
    }

    // appelée à chaque saisie sur les champs de recherche
    const handleInputChange = (e) => {
        const value = e.target.value;
        setState({ ...state, [e.target.name]: value })
    }

    // appeler lors de la sélection sur les listes déroulantes
    const handleSelect = (e) => {
        //console.log(e.target.value);
        //console.log(e.target.name);
        const value = e.target.value;
        if (e.target.name === "mois") {
            setState({ ...state, [e.target.name]: parseInt(value) + 1 })
        } else {
            //console.log(value)
            setState({ ...state, [e.target.name]: value })
        }
    }

    useEffect(() => {
        props.handleSearchFilter(state)
        //setState({service:"All"})
    }, [state])//à chaque changement des filtres on notifie calendrierconges de nos filtres de recherche

    //console.log(state);
    return (
        <>
            <Form>
                <Row>
                    <Col>
                        <FloatingLabel controlId="floatingInputGrid" label="Nom de l'employé">
                            <Form.Control
                                type="text"
                                placeholder="Nom de l'employé"
                                name="nom"
                                onChange={handleInputChange}
                                value={state.nom} />
                        </FloatingLabel>
                    </Col>
                    <Col>
                        <FloatingLabel controlId="floatingSelectGrid" label="Sélectionner mois" >
                            <Form.Select aria-label="mois" onChange={handleSelect} name="mois" defaultValue={state.mois - 1}>
                                {(mois && mois.length > 0) && (
                                    mois.map((an, index) => (
                                        <option value={index} key={index}>{an}</option>
                                    ))
                                )}
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                    <Col>
                        <FloatingLabel controlId="floatingSelectGrid" label="Sélectionner année" >
                            <Form.Select aria-label="annee" onChange={handleSelect} name="annee" defaultValue={state.annee}>
                                {(annee && annee.length > 0) && (
                                    annee.map((an, index) => (
                                        <option value={an} key={index}>{an}</option>
                                    ))
                                )}
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                    <Col className="d-grid gap-1">
                        <Button variant="success" size="lg" onClick={effacerFiltres}>
                            Réinitialiser filtres
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default SearchMoisConge