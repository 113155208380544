import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'

import DemanderConge from './DemanderConge'
import Alerte from './Alerte';
import AgentService from '../services/agent.service'
import ResultatInsertion from './ResultatInsertion';

const StatsConges = (props) => {
    const [show, setShow] = useState(false);
    const [success, setSuccess] = useState("");
    const [agent, setAgent] = useState(null);
    const [stat, setStat] = useState([]);
    const [decision, setDecision] = useState(props.stat)

    //console.log(decision);
    const handleClose = (arg) => {
        setShow(arg)
        window.location.reload(false);
    };
    const handleShow = () => setShow(true);

    const showSuccess = (success) => {
        //console.log(success);
        setSuccess(success)
    }

    useEffect(() => {
        if (!props.agent.hasOwnProperty("message")) {
            setAgent(props.agent)
        }
    }, [])

    useEffect(() => {
        if (agent !== null) {
            getAgentStat()
        }
    }, [agent])

    const getAgentStat = () => {
        //console.log(agent);
        AgentService.getAgentStats(agent.id_agent)
            .then((data) => {
                setStat(data[0]);
            })
    }

    return (
        <>
            {agent === null ? <Alerte variant="alert" message="Agent non trouvé" /> :
                <>
                    {stat === null || stat === undefined ? <Alerte variant="alert" message="Aucune statistique disponible" /> :
                        <>
                            {/*<h5 className="dash-title">Statistique congés</h5>*/}
                            <div className="card mb-0">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="time-list">
                                                <div className="dash-stats-list">
                                                    <h4>{stat.hasOwnProperty("conges_pris") && stat.conges_pris} </h4>
                                                    <p>jours déjà jouis</p>
                                                </div>
                                                <div className="dash-stats-list">
                                                    <h4>{stat.hasOwnProperty("conges_restants") && stat.conges_restants}</h4>
                                                    <p>jours restants</p>
                                                </div>
                                            </div>
                                            <div className="request-btn">
                                                <div class="dash-stats-list">
                                                    <h4>{stat.hasOwnProperty("solde_conges") && stat.solde_conges}</h4>
                                                    <p>Solde de congés depuis embauche</p>
                                                </div>
                                                <Button variant="primary" onClick={handleShow} className="btn-custom">
                                                    Demander congé
                                                </Button>
                                            </div>
                                            {show && <DemanderConge show={show} handleClose={handleClose} showSuccess={() => showSuccess(false)} stat={props.stat} agent={agent} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>}
                </>}
        </>
    )
}

export default StatsConges