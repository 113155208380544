import React, { useState, useRef, useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import Alerte from './Alerte'
import Form from 'react-validation/build/form'
import Input from 'react-validation/build/input'
import CheckButton from 'react-validation/build/button'

import AuthService from '../services/auth.service'

import logo from "../assets/img/Logo-PREA.png"

/*const style = {
    color: '#FF0000'
}*/
//Validations
//Champ requis
const required = (value) => {
    if (!value) {
        return (
            <Alerte variant="danger" message="Ce champ est obligatoire!" />
        )
    }
}

const Login = () => {
    const user = AuthService.getCurrentUser()
    const form = useRef()
    const checkBtn = useRef()

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState("")

    //const [showAdminBoard, setShowAdminBoard] = useState(false)
    const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser())
    //console.log(AuthService.getCurrentUser());

    useEffect(() => {
        if (user) {
            setCurrentUser(user)
            //setShowModeratorBoard(user.roles.includes("ROLE_MODERATOR"))
            //setShowAdminBoard(user.roles.includes("ROLE_ADMIN"))
        }
    }, [user])

    const onChangeUsername = (e) => {
        setUsername(e.target.value)
    }
    const onChangePassword = (e) => {
        setPassword(e.target.value)
    }
    const handleLogin = (e) => {
        e.preventDefault()

        setMessage("")
        setLoading(true)

        form.current.validateAll()

        if (checkBtn.current.context._errors.length === 0) {
            AuthService.login(username, password).then((value) => {
                if (value.hasOwnProperty('message')) {
                    setLoading(false)
                    setMessage(value.message)
                } else {
                    setCurrentUser(AuthService.getCurrentUser())
                }
            }
            )
        } else {
            setLoading(false)
        }
    }
    return (
        currentUser ? (<Navigate to="/home" />) : (<>
            <div className="account-content">
                <div className="container">
                    <div className="account-logo">
                        <a href="index.html">
                            <img src={logo} alt="logo PREA" />
                        </a>
                    </div>

                    <div className="account-box">
                        <div className="account-wrapper">
                            <h3 className="account-title">Connectez-vous</h3>
                            <p className="account-subtitle">Accéder à l'application de gestion de personnel du PREA</p>
                            <Form onSubmit={handleLogin} ref={form}>
                                <div className="form-group">
                                    <label>Nom d'utilisateur</label>
                                    <Input className="form-control" type="text" name="username" value={username}
                                        onChange={onChangeUsername} validations={[required]} />
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col">
                                            <label>Mot de passe</label>
                                        </div>
                                    </div>
                                    <Input className="form-control" type="password" value={password} name="password"
                                        onChange={onChangePassword} validations={[required]} />
                                </div>
                                <div className="form-group text-center">
                                    <button className="btn btn-primary account-btn" disabled={loading} type="submit">
                                        {loading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span>Se connecter</span></button>
                                </div>

                                {message && (
                                    <Alerte variant="danger" message={message} />
                                )}
                                <CheckButton style={{ display: "none" }} ref={checkBtn} />
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    )
}
export default Login