import React, { useState, useEffect } from "react";
import { Navigate, Link } from "react-router-dom";
import { Nav, NavDropdown, Dropdown } from "react-bootstrap";

import AuthService from "../services/auth.service";
import AgentService from "../services/agent.service";
import DemandeService from "../services/demande.service";

import Avatar from "./Avatar";

/*import avatar from "../assets/img/profiles/avatar-02.jpg"
import avatar21 from "../assets/img/profiles/avatar-21.jpg"
import logo from "../assets/img/logo.png"*/

const usernameStyle = {
  color: "#fff",
  padding: "5px",
};

const logout = () => {
  AuthService.logout();
  //window.location.href = "/";
  //window.location.reload(true);
};

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    className="dropdown-toggle nav-link"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <div className="topnav-dropdown-header">
          <span className="notification-title">Demandes</span>
          {/*<a href="" className="clear-noti"> Marqué comme lu</a>*/}
        </div>

        <div className="noti-content">
          <ul className="notification-list">{React.Children.toArray(children)}</ul>
        </div>
        <div className="topnav-dropdown-footer">
          <Link to={`/demandes`}>Voir toutes les demandes</Link>
        </div>
      </div>
    );
  }
);

// get agent info by id
export const getAgentInfo = async (id) => {
  try {
    let agent = await AgentService.getAgentById(id);
    //console.log(agent);
    return agent;
  } catch (error) {
    console.warn(error.message);
    throw error;
  }
};

const CustomItem = (props) => {
  //console.log(props);
  return (
    <a href="">
      <div className="media">
        <span className="avatar">
          <Avatar url={props.avatar && props.avatar} />
        </span>
        <div className="media-body">
          <p className="noti-details">
            <a
              href={`/demandes/${props.idDemande}`}
              style={{ color: "#989c9e" }}
            >
              <span className="noti-title">{props.prenom}</span> a déposé une
              demande de congé.
            </a>
          </p>
          {/*<p className="noti-time"><span className="notification-time">Le {props.datedemande}</span></p>*/}
        </div>
      </div>
    </a>
  );
};

//check if prédécesseur (demande.ordre) pas déjà validé(renvoie true si pas encore validé)
export const checkOdreDemande = (demande, demandesEnAttente, userId) => {
  let nonvalide = false;
  if (userId) {
    if (demandesEnAttente.length > 0) {
      let statuts = demandesEnAttente.filter(
        (element) => element.id_demande === demande.id_demande
      );
      let ordreEtStatutUserConnected = statuts.find(
        (o) => o.id_utilisateur === userId
      );
      let ordre = ordreEtStatutUserConnected.ordre;
      //si ordre user connected = 1 et statut = 0 => demande a besoin d'être validé par cet user
      if (ordre == 1 && ordreEtStatutUserConnected.statut == 0) {
        return true;
      } else {
        //rechercher dans statuts les demandes dont ordre < à user connecté
        let statutsInf = statuts.filter((element) => element.ordre < ordre);
        console.log("ireto avy ireo statut_demande inférieur à moi");
        console.log(statutsInf);
        if (statutsInf.length > 0) {
          //d'autres utilisateurs doivent valider cette demande avant moi
          //check si ces utilisateurs ont déjà effectué cette validation
          if (
            statutsInf.every((v) => {
              if (v.statut == 0) {
                //non validé
                return false;
              }
              // Make sure you return true. If you don't return a value, `every()` will stop.
              return true;
            })
          ) {
            //ils ont déjà effectué leurs validations
            nonvalide = true;
            return nonvalide;
          }
        }
      }
    }
    return nonvalide;
  }
};

const Header = () => {
  const user = AuthService.getCurrentUser();
  const [agent, setAgent] = useState([]);

  const [key, setKey] = useState("profile");
  const [userinfo, setUserinfo] = useState("");
  const [demandesEnAttente, setDemandesEnAttente] = useState("");
  const [demandesEnAttenteForMe, setDemandesEnAttenteForMe] = useState("");

  useEffect(() => {
    if (user) {
      getConnectedUserinfo();
      getDemandeEnAttente();
      /*console.log(demandesEnAttente);
            if (demandesEnAttente.length > 0) console.log(getDemandeEnAttenteForConnectedUser())*/
    }
  }, []);

  useEffect(() => {
    let tableauTemp_agentDejaVerifie = [];
    let lesDemandes = [];
    if (demandesEnAttente.length > 0) {
      //vérification si demandesEnAttente.id_agent déjà dans liste des agents dont on collecte les infos(avatar,prénom)
      demandesEnAttente.forEach((element) => {
        if (tableauTemp_agentDejaVerifie.indexOf(element.id_agent) === -1) {
          //pas encore dedans
          tableauTemp_agentDejaVerifie.push(element.id_agent);
        }
      });

      setDemandesEnAttenteForMe(getDemandeEnAttenteForConnectedUser());
    }

    //on récupère les infos de chaque agent
    //console.log(tableauTemp_agentDejaVerifie);
    /*const key = 'id_agent';
        const arrayUniqueByKey = [...new Map(demandesEnAttente.map(item =>
            [item[key], item])).values()];*/
    tableauTemp_agentDejaVerifie.forEach((element) => {
      AgentService.getAgentById(element)
        .then((value) => {
          if (agent.indexOf(value) === -1) {
            //pas encore dedans
            setAgent((agent) => [...agent, value]);
          }
        })
        .catch((err) => {
          console.warn(err.message);
        });
    });
  }, [demandesEnAttente]);

  //retrieve user info
  const getConnectedUserinfo = () => {
    AgentService.getAgentById(user.id_agent)
      .then((data) => {
        setUserinfo(data);
      })
      .catch((err) => {
        console.warn(err.message);
      });
  };

  // get all demandes en attente
  const getDemandeEnAttente = () => {
    //console.log(user);
    DemandeService.getAllDemandesEnattente()
      .then((data) => {
        //console.log(data);
        setDemandesEnAttente(data);
      })
      .catch((err) => {
        console.warn(err.message);
      });
  };

  // get all demandes à valider par cet utilisateur
  const getDemandeEnAttenteForConnectedUser = () => {
    let demandes = [];
    if (demandesEnAttente.length > 0) {
      //filtrer les demandes à valider par cet utilisateur
      let lesDemandes = demandesEnAttente.filter(
        (o) => o.id_utilisateur == user.id
      );
      //console.log(lesDemandes);
      if (lesDemandes.length > 0) {
        //j'ai des demandes à valider, reste à vérifier prédécesseur
        lesDemandes.forEach((demande) => {
          if (checkOdreDemande(demande, demandesEnAttente, user.id)) {
            demandes.push(demande);
            //setDemandesEnAttenteForMe(demandesEnAttenteForMe => [...demandesEnAttenteForMe, demande])
          }
        });
      }
    }
    return demandes;
  };

  //handle Navbar item selection
  const handleSelect = (newKey) => {
    setKey(newKey);
    switch (newKey) {
      case "profile":
        console.log("take me to profile page");
        //console.log(user);
        break;
      case "logout":
        console.log("i want to logout");
        logout();
        break;
      default:
        console.log("key not found");
    }
  };

  //console.log(agent);
  return user ? (
    <div className="header">
      <div className="page-title-box">
        <Link to={`/`}>
          <h3>Gestion Personnels PREA</h3>
        </Link>
      </div>

      {/*<a id="mobile_btn" class="mobile_btn" href="#sidebar"><i class="fa fa-bars"></i></a>*/}
      <Nav as="ul" className="nav user-menu" onSelect={handleSelect}>
        <Dropdown as="li" className="nav-item dropdown" align="end">
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            <>
              <i className="fa fa-bell-o"></i>{" "}
              <span className="badge badge-pill">
                {demandesEnAttenteForMe && demandesEnAttenteForMe.length > 0
                  ? demandesEnAttenteForMe.length
                  : 0}
              </span>
            </>
          </Dropdown.Toggle>
          {demandesEnAttenteForMe.length > 0 ? (
            <Dropdown.Menu as={CustomMenu} className="notifications">
              {demandesEnAttenteForMe.map((demande) => {
                let agentTemp = agent.find(
                  (o) => o.id_agent == demande.id_agent
                );
                //console.log(user);
                if (user) {
                  //on n'affiche que les demandes dont le statut=0, utilisateur qui doit approuver la demande est celui qui est connecté, la demande a déjà été validée par son prédécesseur
                  if (agentTemp) {
                    return (
                      <Dropdown.Item
                        as="li"
                        key={demande.id_demande}
                        eventKey={demande.id_demande.toString()}
                        bsPrefix="notification-message"
                      >
                        <CustomItem
                          avatar={agentTemp.avatar && agentTemp.avatar}
                          prenom={agentTemp.prenom && agentTemp.prenom}
                          idDemande={demande.id_demande && demande.id_demande}
                        />
                      </Dropdown.Item>
                    );
                  }
                }
              })}
            </Dropdown.Menu>
          ) : (
            <Dropdown.Menu>
              <Dropdown.ItemText>Aucune demande en attente</Dropdown.ItemText>
            </Dropdown.Menu>
          )}
        </Dropdown>
        <NavDropdown
          as="li"
          title={
            <>
              <span className="user-img">
                <Avatar url={userinfo.avatar} />
              </span>
              <span style={usernameStyle}>{userinfo.abbr_fonction}</span>
            </>
          }
          id="basic-nav-dropdown"
          className="has-arrow main-drop nav-item dropdown"
        >
          <NavDropdown.Item href={`/profile/${user.id_agent}`}>
            Mon profil
          </NavDropdown.Item>
          <NavDropdown.Item eventKey="logout">Déconnexion</NavDropdown.Item>
        </NavDropdown>
      </Nav>
    </div>
  ) : (
    <Navigate to="/" />
  );
};

export default Header;
