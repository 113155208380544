import React, { useState, useEffect } from 'react'
import { Form, Button, Modal, FloatingLabel, Row, Col } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import Alerte from './Alerte'
import AuthService from "../services/auth.service";

export const ModalProfile = (props) => {
    //show={showModal} handleClose={handleClose} showSuccess={handleShowModalResult}

    //const [state, setState] = useState(props.show)
    const [show, setShow] = useState(props.show); //ProfileInfo wants to show the modal if true
    const [errMsg, setErrMsg] = useState("")//message d'erreur si mot de passe ne correspondent
    const user = AuthService.getCurrentUser();
    const { register, control, setValue, handleSubmit, formState: { errors } } = useForm()

    const handleAnnuler = () => {
        setShow(false)
        //notifier ProfileInfo que je veux fermer le modal
        props.handleClose(true)
        //props.show(show)
    }

    /*useEffect(() => {
        //if(show) props.handleClose(show)
    }, [show])*/

    const closeModal = () => { //closing modal after the success of the update
        // fermeture modal, notifier ProfileInfo de ne plus afficher le modal et le fermer
        setShow(false);
        //props.handleClose(true);
        props.showSuccess()
    }
    //const handleShow = () => setShow(true);
    const onSubmit = data => {
        //console.log(user);
        if (user) {
            AuthService
                .update(data.ancien, data.nouveau, user.id)
                .then(data => {
                    closeModal()//succès update
                })
                .catch(err => {
                    console.warn(err.message);
                    setErrMsg("Ancien mot de passe erroné")
                    //closeModal(0)//echec update
                })
        }
    }

    //console.log(show);
    return (
        <Modal show={show} onHide={handleAnnuler} className="custom-modal" centered>
            <Modal.Header closeButton>
                <Modal.Title>Modifier mot de passe</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {/*<div class="form-group">
            <label>Leave Type <span class="text-danger">*</span></label>
            <select class="select">
                <option>Select Leave Type</option>
                <option>Casual Leave 12 Days</option>
                <option>Medical Leave</option>
                <option>Loss of Pay</option>
            </select>
            </div>*/}
                    <Row className="mb-3 align-items-center">
                        <FloatingLabel
                            controlId="ancien_pwd"
                            label="Ancien mot de passe"
                            className="mb-3"
                        >
                            <Form.Control
                                type="password"
                                placeholder="Ancien mot de passe"
                                {...register("ancien", { required: true })} />
                            {errors.ancien && <Alerte className="mt-3" variant="danger" message="Veuillez renseigner votre ancien mot de passe!" />}
                            {errMsg != "" && <Alerte className="mt-3" variant="danger" message={errMsg} />}
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="nouveau_pwd"
                            label="Nouveau mot de passe"
                            className="mb-3"
                        >
                            <Form.Control
                                type="password"
                                placeholder="Nouveau mot de passe"
                                {...register("nouveau", { required: true })} />
                            {errors.nouveau && <Alerte className="mt-3" variant="danger" message="Veuillez renseigner votre nouveau mot de passe!" />}
                        </FloatingLabel>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className="submit-section">
                <Button variant="primary" onClick={handleSubmit(onSubmit)} className="submit-btn">
                    Enregistrer</Button>
            </Modal.Footer>
        </Modal>
    )
}
