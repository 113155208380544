import React from 'react'

const BoardAdmin = () => {
    //const [content, setContent] = useState("")

    /*useEffect(() => {
        UserService.getAdminBoard().then(
            (response) => {
                setContent(response.data)
            },
            (error) => {
                const _content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
                setContent(_content)
            }
        )
    }, [])*/

    return (
        <>
            {/*<h3>{content}</h3>*/}
            <h3>Welcome to Board Admin!</h3>
        </>
    )
}

export default BoardAdmin