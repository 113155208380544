import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';

import { Form, Row, Button, Table, Col, Container } from "react-bootstrap";
import { useForm, FormProvider, useFormContext, Controller } from 'react-hook-form'
import Alerte from './Alerte'

import Header from "./Header";
import Sidebar from "./Sidebar";
import PageHeader from "./PageHeader";

import CritereItem from "./CritereItem";

import { getAgentInfo } from "./Header";

import CritereService from '../services/critere.service';
import SouscritereService from '../services/souscritere.service';
import ResultatEvaluation from '../services/resultat_evaluation.service';
import EvaluationService from '../services/evaluation.service';
import AuthService from "../services/auth.service";

export const getSousCriteres = async (id) => {
    try {
        if (id) {
            let souscriteres = await SouscritereService.getSousCriteresByCritere(id)
            return souscriteres
        } else {
            let souscriteres = await SouscritereService.getSousCriteres()
            return souscriteres
        }

    } catch (error) {
        console.warn(error.message);
        throw error
    }
}

export const getCriteres = async () => {
    try {
        let criteres = await CritereService.getCriteres()
        return criteres
    } catch (error) {
        console.warn(error.message);
        throw error
    }
}
const Evaluation = () => {
    const { id } = useParams()
    const evaluateur = AuthService.getCurrentUser();
    const [agent, setAgent] = useState("");
    const [observationGenerale, setObsbservationGenerale] = useState("");
    const [totalNote, setTotalNote] = useState("");
    const [totalBareme, setTotalBareme] = useState(0)
    const [totalPoints, setTotalPoints] = useState(0)
    const [souscriteres, setSousCriteres] = useState([])
    const [idEval, setIdEval] = useState(0)

    //console.log(evaluateur);
    //console.log(agent);

    const [criteres, setCriteres] = useState([])

    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            totalEval: '0'
        }
    });
    const { register, handleSubmit, watch, setValue, formState: { errors } } = methods;
    const watchAllFields = watch();

    // Callback version of watch.  It's your responsibility to unsubscribe when done.
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            //console.log(value, name, type)
            //éditer totalEval au fur et à mesure qu'on modifie les points des sous criteres
            if (name.includes("ptSouscritere_")) {
                let total = 0
                //récupérer tous les points des sous critères
                for (var key in value) {
                    console.log("" + key);
                    if (key.includes("ptSouscritere_") && value[key]) {
                        total += parseInt(value[key])
                    }
                }
                if (total > 0) {
                    setTotalPoints(total)
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        setValue("totalEval", totalPoints)
    }, [totalPoints])

    useEffect(() => {
        console.log(totalBareme);
        setValue("totalBaremeSousCritere", totalBareme)
    }, [totalBareme])

    useEffect(() => {
        //mettre à jour les champs totalBareme
        if (souscriteres.length > 0) {
            try {
                let total = 0
                souscriteres.map(souscritere => {
                    total += parseInt(souscritere.point_souscritere)
                })
                setTotalBareme(total)
            } catch (error) {
                console.warn(error.message);
            }
        }
    }, [souscriteres]);

    useEffect(() => {
        if (idEval > 0) {
            //redirectToView(idEval)
            window.location = '/evaluation/view/' + idEval
        }
    }, [idEval])

    /*const redirectToView = (idEval) => {
        return <Redirect to={'/evaluation/view/' + idEval} />
    }*/


    //après avoir cliqué sur envoyer les résultats
    const onSubmit = data => {
        console.log(data)
        //insérer resultat évaluation puis récupérer l'id_resultateval
        let id_resultateval = 0
        let date_evaluation = new Date()
        let total_note = data.totalEval
        let observation_generale = data.observationGenerale

        //console.log("evaluation" + date_evaluation + "" + total_note + "" + observation_generale);
        ResultatEvaluation.create(date_evaluation, total_note, observation_generale, agent.id_agent)
            .then(nouvelleDemande => {
                //console.log(nouvelleDemande);
                if (nouvelleDemande) {
                    //insertion de chaque sous-critère
                    if (souscriteres.length > 0) {
                        try {
                            souscriteres.map(souscritere => {
                                let souscritereTmp = {}
                                //(id_agent, id_souscritere, id_resultateval, observation_notation, point_obtenu)
                                if (data.hasOwnProperty("ptSouscritere_" + souscritere.id_souscritere) && data["ptSouscritere_" + souscritere.id_souscritere]) {
                                    souscritereTmp.point_obtenu = data["ptSouscritere_" + souscritere.id_souscritere]
                                }
                                if (data.hasOwnProperty("obsSouscritere_" + souscritere.id_souscritere) && data["obsSouscritere_" + souscritere.id_souscritere]) {
                                    souscritereTmp.observation_notation = data["obsSouscritere_" + souscritere.id_souscritere]
                                }
                                EvaluationService.create(agent.id_agent, souscritere.id_souscritere, nouvelleDemande.id_resultateval, souscritereTmp.observation_notation, souscritereTmp.point_obtenu)
                                    .then(data => {
                                        if (data) {
                                            console.log(data);
                                            setIdEval(nouvelleDemande.id_resultateval)
                                        }
                                    })
                                    .catch(err => {
                                        console.warn(err.message);
                                    })
                            })
                        } catch (error) {
                            console.warn(error.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.warn(err.message);
            })
    };

    useEffect(() => {
        getCriteres()
            .then(data => {
                console.log(data);
                setCriteres(data)
            })
        getSousCriteres()
            .then(data => {
                console.log(data);
                setSousCriteres(data)
            })
        setValue("observationGenerale", observationGenerale)
        setValue("totalEval", totalPoints)
        setValue("totalBaremeSousCritere", totalBareme)
        if (id) {
            console.log(id);
            getAgentInfo(id)
                .then((agent) => {
                    //console.log(agent);
                    setAgent(agent);
                })
                .catch((err) => {
                    console.warn(err.message);
                });
        }
    }, [])

    console.log(totalBareme);
    return (
        <>
            <Header />
            <Sidebar />
            <div className="page-wrapper">
                <Container fluid className="content">
                    <Row>
                        {((agent && evaluateur) && (evaluateur.id_agent == agent.superieur_id || evaluateur.id_agent == 2) && evaluateur.id_agent != agent.id_agent) ? (

                            <>
                                <Col sm={12}>
                                    <PageHeader title={"Formulaire d'Evaluation de performance"} />
                                </Col>
                                <section className="review-section personal-excellence">
                                    <div className="review-header text-center">
                                        <h3 className="review-title">Evaluation de performance de l'agent: &laquo; <span style={{ color: "#ff9b44" }}>{agent.nom.charAt(0).toUpperCase() + agent.nom.slice(1)} {agent.prenom.charAt(0).toUpperCase() + agent.prenom.slice(1)}</span> &raquo;</h3>
                                        {/*<p className="text-muted">Lorem ipsum dollar</p>*/}
                                    </div>
                                    <FormProvider {...methods} >
                                        <Form>
                                            <Form.Group controlId="formEvaluation">
                                                <Table
                                                    responsive
                                                    bordered
                                                    className='review-table mb-0'
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: "40px" }}>#</th>
                                                            <th>Critère à évaluer</th>
                                                            <th>Indicateurs</th>
                                                            <th>Note</th>
                                                            <th>Note attribuée</th>
                                                            <th>Observation</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            (criteres && criteres.length > 0) && (
                                                                criteres.map(item => <CritereItem critere={item} key={item.id_critere} />)
                                                            )
                                                        }
                                                        <tr>
                                                            <td colSpan="3" class="text-center">Note de performance totale <br />
                                                                avec Observation générale</td>
                                                            <td><Form.Group className="mb-3" controlId="totalBaremeSousCritere">
                                                                <Form.Control type="number" {...register("totalBaremeSousCritere")} readOnly />
                                                            </Form.Group></td>
                                                            <td><Form.Group className="mb-3" controlId="totalEval">
                                                                <Form.Control type="number" {...register("totalEval")} readOnly />
                                                            </Form.Group></td>
                                                            <td><Form.Group className="mb-3" controlId="observationGenerale">
                                                                <Form.Control
                                                                    type="text"
                                                                    {...register("observationGenerale")} />
                                                            </Form.Group></td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="6" className="text-center">
                                                                <div className="grade-span">
                                                                    <h4>Notation</h4>
                                                                    <span className="badge bg-inverse-danger">Inférieur à 65 Insuffisant</span>
                                                                    <span className="badge bg-inverse-warning">65-74 Moyen</span>
                                                                    <span className="badge bg-inverse-info">75-84 Satisfaisant</span>
                                                                    <span className="badge bg-inverse-purple">85-92 Bon</span>
                                                                    <span className="badge bg-inverse-success">Supérieur à 92 Excellent</span>
                                                                </div>
                                                                <Button variant="primary" onClick={handleSubmit(onSubmit)} className="submit-btn mt-3">
                                                                    Envoyer les résultats
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Form.Group>
                                        </Form>
                                    </FormProvider>
                                </section>
                            </>
                        ) : (<Alerte variant="danger" message="Vous n'avez pas le droit d'évaluer cet agent. Vous devez être le coordonnateur général ou son supérieur hiérarchique." />)}
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Evaluation