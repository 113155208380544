import React from 'react'
import { Link } from 'react-router-dom'

const UserInfo = (props) => {
    return (
        <>
            <h4 class="user-name m-t-10 mb-0 text-ellipsis">
                <Link to={`/profile/${props.personnel.id_agent}`}>{(props.personnel.nom + ' ' + props.personnel.prenom).toString()}</Link>
            </h4>
            <div class="small text-muted">{props.personnel.fonction}</div>
            <div class="staff-id">I.M. : {props.personnel.im.replace(/.{1,2}(?=(.{3})+$)/g, '$& ')} </div>
        </>
    )
}

export default UserInfo
