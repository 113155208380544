import React, { useEffect, useState } from 'react';
import { AgentInfo } from './DemandeItem';
import { lastday } from './CalendrierConges'

const LigneCalendrierItem = (props) => {
    const conge = props.conges
    const renderSwitch = (conge) => {
        switch (conge) {
            case 1://xv
                return (
                    <td>
                        <div className="half-day">
                            <span className="first-off">-</span>
                            <span className="first-off"><i className="fa fa-check text-success"></i></span>
                        </div>
                    </td>
                )
            case 2:
                return (
                    <td>
                        <div className="half-day">
                            <span className="first-off"><i className="fa fa-check text-success"></i></span>
                            <span className="first-off">-</span>
                        </div>
                    </td>
                )
            case 3:
                return (
                    <td>
                        <i className="fa fa-check text-success"></i>
                    </td>
                )
            default:
                return <td>-</td>
        }
    }
    return (<>{renderSwitch(conge)}</>

    )
}

const LigneCalendrierMensuel = (props) => {
    //récupération des props venant de CalendrierMensuel.js
    const agent = props.agent //agent dont on veut afficher la ligne de congé
    const jours = props.jours //nombre de jours du mois sélectionné
    const listeConges = props.listeConges //les congés du mois sélectionné
    const mois_choisi = props.mois
    const [congesAfficher, setCongesAfficher] = useState([])

    //console.log(agent);
    //console.log(jours);
    //console.log(listeConges);

    useEffect(() => {
        //filtre la liste de congé par agent
        setCongesAfficher(getListeCongesAgent(agent.id_agent, listeConges))

    }, [agent, listeConges, jours])

    const getListeCongesAgent = (agentID, listeConges) => {
        let tab = []
        let dates = []
        //Création tableau allant contenir jours
        jours.map(element =>
            tab[element] = 0
        )
        jours.map(element =>
            dates[element] = 0
        )
        let congesFiltered = listeConges.filter(conges => conges.id_agent == agentID) //filtrer par agent
        //console.log(congesFiltered);
        if (congesFiltered.length > 0) {//cet agent a des congés à afficher
            congesFiltered.map(conges => {
                //console.log(conges)
                //si date début égale 12:00:00 
                const debut_conge = new Date(conges.debut_conge)
                const fin_conge = new Date(conges.fin_conge)
                const nb_jours = conges.nb_jours

                const jour_debut = debut_conge.getDate()
                const jour_fin = fin_conge.getDate() //jour à comparer

                let nombre_de_jours = Number(nb_jours)
                let dateFinMois = lastday(props.annee, props.mois - 1)

                if (!Number.isInteger(nombre_de_jours)) {//jour avec demi-journée ex: 0.5,2.5,8.5
                    nombre_de_jours -= 0.5
                }
                let all_dates_from_start_to_end = []//si 28/08/19-03/09
                let tmp_date = new Date(conges.debut_conge)//première date à insérer: date début
                all_dates_from_start_to_end.push(tmp_date)

                //insérer les dates qui suivent la date début
                for (let i = 1; i < nombre_de_jours; i++) {
                    // ajouter un jour à chaque date
                    all_dates_from_start_to_end[i] = new Date(all_dates_from_start_to_end[i - 1])
                    all_dates_from_start_to_end[i].setTime(all_dates_from_start_to_end[i - 1].getTime() + (24 * 60 * 60 * 1000))
                }
                //console.log(debut_conge);

                let dates_valides = all_dates_from_start_to_end.filter(date => date.getMonth() == mois_choisi - 1);//ne retenir que les dates du mois courant

                if (Number(nb_jours) == 0.5) {// => jour début = 2
                    dates[jour_debut] = 2
                } else {
                    dates_valides.forEach(date => {
                        dates[date.getDate()] = 3
                    })
                    if (debut_conge.getMonth() == mois_choisi - 1 && fullOrHalf(debut_conge.getHours()) == 1) {//début demi-journée
                        //console.log("début demi-journée");
                        dates[jour_debut] = 1
                    }
                    if (fin_conge.getMonth() == mois_choisi - 1 && fullOrHalf(fin_conge.getHours()) == 2) {//date fin demi-journée
                        //console.log("fin demi-journée");
                        dates[jour_fin] = 2
                    }
                }

                // console.log(dates)

                /*let lesDates = []
                //reverse: à partir date début, lister les dates correspondants aux nb_jours ex: 02/09/21;8.5jrs => 02-03-04-05-06-07-08-09-10(AM)
                if (Number(nb_jours) == 0.5) {//début congé == fin congé ex:15/04/22-15/04/22
                    lesDates[debut_conge.getDate()] = 0.5
                } else {
                    lesDates.push(debut_conge)
                    //remplir le tableau par les dates
                    let nbjours = nb_jours - 1
                    for (let i = 0; i < nbjours; i++) {

                    }
                }*/

                /*console.log(debut_conge)
                console.log(fin_conge)*/

                // récupérer date début et fin du mois sélectionné
                //let dateDebutMois = props.annee + "-" + props.mois.toString().padStart(2, 0) + "-01"
                //let dateFinMois = props.annee + "-" + props.mois.toString().padStart(2, 0) + "-" + lastday(props.annee, props.mois - 1)

                // To calculate the time difference of two dates
                //var Difference_In_Time = debut_conge.getTime() - d1.getTime();

                // To calculate the no. of days between two dates
                //var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

                /*const jourDeb = debut_conge.getDate() //jour à comparer
                const jourFin = fin_conge.getDate() //jour à comparer
                tab[jourDeb] = fullOrHalf(debut_conge.getHours())
                tab[jourFin] = fullOrHalf(fin_conge.getHours())
                for (let i = jourDeb + 1; i < jourFin; i++) {
                    tab[i] = 3
                }*/
            })
        }
        return dates
    }

    //retourne 1(journée pleine: 00 heure) ou 0.5(12:00)
    const fullOrHalf = (heure) => {
        //half 1: 12:00-23:59 xv
        //half 2: 00:00-11:59 vx
        //full 3 :00-23:59
        if (heure == 12) return 1
        else if (heure == 11) return 2
        return 3
    }

    //console.log(congesAfficher);
    return (
        <tr>
            <td>
                {agent.id_agent && <AgentInfo id={agent.id_agent} />}
                {agent.im && agent.im.replace(/.{1,2}(?=(.{3})+$)/g, "$& ")}
            </td>
            {
                congesAfficher && (
                    congesAfficher.map((element, index) => <LigneCalendrierItem key={index} conges={element} />)
                )
            }
        </tr >
    )
}

export default LigneCalendrierMensuel