import axios from 'axios';
import { API_baseUrl } from './config';
import authHeader from './auth-header';


const API_url = API_baseUrl + "/agents/"
//const API_url = "http://localhost:8082/api/agents/"

// get liste des employés
const getEmployes = () => {
    return axios.get(API_url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        });
}

// get agent id
const getAgentById = (id) => {
    return axios.get(API_url + id)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        });
}

// GET agent stats 
const getAgentStats = (id) => {
    return axios.get(API_url + 'stats/' + id)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.log(error);
        })
}

// GET agent cadres 
const getAgentCadres = () => {
    const response = axios.get(API_url + 'cadres/')
    const dataResponse = response
        .then((response) => response.data)
    return dataResponse
}

// GET agent stats 
const getAgentDecisions = (id) => {
    return axios.get(API_url + 'decision/' + id)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.log(error);
        })
}

const exports = {
    getEmployes,
    getAgentById,
    getAgentStats,
    getAgentDecisions,
    getAgentCadres
}

export default exports