import React from 'react'
import { Row } from 'react-bootstrap';

import EmployeItem from './EmployeItem';
import Alerte from './Alerte';

const ListeEmployes = (props) => {
    return (
        <>
            {props.personnels.length !== 0 ? (
                <Row className="mt-5">
                    {props.personnels.map(personnel => (
                        <EmployeItem personnel={personnel} />))}
                </Row>
            ): (<Alerte variant="info" message="Aucun employé ne correspond à votre recherche." />)}
        </>
    )
}

export default ListeEmployes