import React, { useState, useEffect } from 'react';
import { useForm, FormProvider, useFormContext, Controller } from 'react-hook-form'
import { Form, Row, Button, Table, Col, Container } from "react-bootstrap";

import EvaluationService from '../services/evaluation.service'

export const getResultats = async (id_resultateval) => {
    try {
        let resultats = await EvaluationService.getEvaluation(id_resultateval)
        return resultats
    } catch (error) {
        console.warn(error.message);
        throw error
    }

}

const SousCritereItem = (props) => {
    const resultat_evaluation = props.resultatEvaluation
    const souscritere = props.souscritere

    const [lesResultatsEval, setLesResultatsEval] = useState([])
    const [agent, setAgent] = useState([])
    const [lesResultatsEvalFiltered, setLesResultatsEvalFiltered] = useState([])//pour un souscritere

    const {
        register,
        formState: { errors }
    } = useFormContext();

    useEffect(() => {
        if (resultat_evaluation) {
            getResultats(resultat_evaluation[0].id_resultateval)
                .then(data => {
                    //console.log(data);
                    setLesResultatsEval(data)
                })
        }
    }, [])

    useEffect(() => {
        if (lesResultatsEval.length > 0) {
            setLesResultatsEvalFiltered(lesResultatsEval.filter(resultat => resultat.id_souscritere == souscritere.id_souscritere))
        }
    }, [lesResultatsEval])


    //console.log(lesResultatsEval);
    //console.log(lesResultatsEvalFiltered);
    return (
        <tr>
            <td>{souscritere.libelle_souscritere}</td>
            <td>
                <Form.Group className="mb-3" controlId="baremeSousCritere">
                    <Form.Control type="number" value={souscritere.point_souscritere && souscritere.point_souscritere} readOnly />
                </Form.Group>
            </td>
            {(lesResultatsEvalFiltered.length > 0) ? (<>
                <td><Form.Group className="mb-3" controlId="formPointSousCritere">
                    <Form.Control
                        type="number"
                        value={lesResultatsEvalFiltered[0].point_obtenu && lesResultatsEvalFiltered[0].point_obtenu}
                        readOnly
                    />
                </Form.Group></td>
                <td><Form.Group className="mb-3" controlId="formObservationSousCritere">
                    <Form.Control
                        type="text"
                        value={lesResultatsEvalFiltered[0].observation_notation && lesResultatsEvalFiltered[0].observation_notation}
                        readOnly
                    />
                </Form.Group></td>
            </>) : (<>
                <td><Form.Group className="mb-3" controlId="formPointSousCritere">
                    <Form.Control
                        type="number"
                        min={0}
                        max={souscritere.point_souscritere}
                        {...register("ptSouscritere_" + souscritere.id_souscritere, {
                            min: {
                                value: 0,
                                message: "Valeur minimale zéro"
                            },
                            max: {
                                value: souscritere.point_souscritere,
                                message: "Valeur maximale " + souscritere.point_souscritere
                            },
                            required: "Veuillez entrer une note"
                        })
                        }
                        defaultValue={0}
                        className={`${errors["ptSouscritere_" + souscritere.id_souscritere] ? 'is-invalid' : ''}`}
                    />

                    {errors["ptSouscritere_" + souscritere.id_souscritere] && <div className="invalid-feedback">{errors["ptSouscritere_" + souscritere.id_souscritere].message}</div>}
                </Form.Group></td>
                <td><Form.Group className="mb-3" controlId="formObservationSousCritere">
                    <Form.Control
                        type="text"
                        {...register("obsSouscritere_" + souscritere.id_souscritere)}
                    />
                    {errors["obsSouscritere_" + souscritere.id_souscritere] && <p>{errors["obsSouscritere_" + souscritere.id_souscritere].message}</p>}
                </Form.Group></td>
            </>)}
        </tr>
    )

}

export default SousCritereItem