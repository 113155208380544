import axios from 'axios';
import authHeader from './auth-header';
import { API_baseUrl } from './config';

//const API_url = "http://localhost:8082/api/resultat_evaluation/"
const API_url = API_baseUrl + "/resultat_evaluation/"

//insérer résultat évaluation
const create = (date_eval, note_totale, observation_generale,agent_evaluer) => {
    let nouvelleEvaluation = {}
    return axios.post(API_url, {
        date_eval,
        note_totale,
        observation_generale,
        agent_evaluer
    })
        .then((response) => {
            if (response.hasOwnProperty("data")) {
                nouvelleEvaluation = response.data
            }
            return nouvelleEvaluation
        })
        .catch(error => {
            return error.response.data
        })
}

// get liste des évaluations
const getEvaluationResult = (id) => {
    return axios.get(API_url + id)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        });
}

// get toutes les évaluations
const getAllEvaluationResult = () => {
    return axios.get(API_url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        });
}

const exports = {
    create,
    getEvaluationResult,
    getAllEvaluationResult
}

export default exports