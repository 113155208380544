import React, { useState, useEffect } from 'react'
import ResultatEvaluationService from '../services/resultat_evaluation.service'
import { Row, Table, Col, Container } from "react-bootstrap";

import Header from "./Header";
import Sidebar from "./Sidebar";
import PageHeader from "./PageHeader";
import EvaluationItem from "./EvaluationItem"

// Component listant toutes les évaluations
const Evaluations = () => {
    const [evaluations, setEvaluations] = useState([])

    useEffect(() => {
        ResultatEvaluationService.getAllEvaluationResult()
            .then(data => {
                if (data) {
                    setEvaluations(data)
                }
            })
    }, [])

    //console.log(evaluations);
    return (
        <>
            <Header />
            <Sidebar />
            <div className="page-wrapper">
                <Container fluid className="content">
                    <Row>
                        <Col sm={12}>
                            <PageHeader title={"Les évaluations de performance enregistrées"} />
                        </Col>
                    </Row>
                    {(evaluations &&
                        evaluations.length > 0) ? (
                        <Row>
                            <Col md={12}>
                                <Table
                                    responsive
                                    striped
                                    className="custom-table mb-0 datatable"
                                >
                                    <thead>
                                        <tr>
                                            <th>Date de l'évaluation</th>
                                            <th>Agent évalué</th>
                                            <th>Par</th>
                                            <th>Note obtenue</th>
                                            <th>Observation générale</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {evaluations.map((element) => (
                                            <EvaluationItem evaluation={element} key={element.id_evaluation} />
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    ) : (
                        <p>Aucune évaluation pour le moment.</p>
                    )}
                </Container>
            </div>
        </>
    );
}

export default Evaluations