import React from 'react'
import Alerte from './Alerte';
import fr from "date-fns/locale/fr";

const { format } = require("date-fns");

const DemandeRecents = (props) => {
    const derniereDemande = props.demande
    return (
        <>{derniereDemande.length <= 0 ? (<Alerte variant="info" message="Aucune demande récente" />) : (<div class="card flex-fill">
            <div class="card-body">
                <h4 class="card-title">Statut dernière demande {/*<span class="badge bg-inverse-danger ml-2">5</span>*/}</h4>
                <div class="leave-info-box">
                    {/*<div class="media align-items-center">
                    <a href="profile.html" class="avatar"><img alt="" src="assets/img/user.jpg"/></a>
                    <div class="media-body">
                        <div class="text-sm my-0">Martin Lewis</div>
                    </div>
                    </div>*/}
                    <div class="row align-items-center mt-3">
                        <div class="col-8">
                            <h6 class="mb-0">Envoyée le: {format(new Date(derniereDemande[0].date_demande), "d MMMM yyyy", { locale: fr })}</h6>
                            <span class="text-sm text-muted">{format(new Date(derniereDemande[0].debut_conge), "d MMMM yyyy", { locale: fr })} au {format(new Date(derniereDemande[0].fin_conge), "d MMMM yyyy", { locale: fr })} ({derniereDemande[0].nb_jours})</span>
                            
                        </div>
                        {derniereDemande[0].approbation == 0 ?
                            (<div class="col-4 text-right">
                                <span class="badge bg-inverse-danger">En attente d'approbation</span>
                            </div>)
                            :
                            (<div class="col-4 text-right">
                                <span class="badge bg-inverse-success">Approuvée</span>
                            </div>)}
                    </div>
                </div>
            </div>
        </div>)}</>
    )
}

export default DemandeRecents
