import React, { useState, useEffect } from 'react'
import StatDecisionItem from './StatDecisionItem'

const StatDecisions = (props) => {
    const [stat, setStat] = useState(props.stat);

    return (
        <>
            {(stat.length > 0) && (
                <div className="card flex-fill dash-statistics">
                    <div className="card-body">
                        <h5 className="card-title">Décision de congés</h5>
                        <div className="stats-list">
                            {
                                stat.map((data)=>{
                                    return <StatDecisionItem stat={data}/>
                                })
                            }
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default StatDecisions
