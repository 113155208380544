//Authentication service
import axios from "axios"
import { API_baseUrl } from './config';

//const API_url = "http://localhost:8082/api/auth/"
const API_url = API_baseUrl + "/auth/"

//création de compte en fournissant username, email, password
const register = (username, email, password) => {
    return axios.post(API_url + 'signup', {
        login,
        email,
        password,
    });
}

//connexion en utilisant username et password, l'API nous renvoie les informations de l'utilsateur connecté avec le JWT que nous allons sauvegarder dans localstorage
const login = (login, password) => {
    return axios.post(API_url + 'signin', {
        login,
        password,
    })
        .then((response) => {
            //console.log("réponse reçu apres appel login");
            //console.log(response);
            if (response.data.accessToken) {
                localStorage.setItem("user", JSON.stringify(response.data))
            }
            return response.data
        })
        .catch((error) => {
            //console.log("erreur signin")
            console.log(error.response);
            return error.response.data
        });
}

//déconnexion
const logout = () => {
    localStorage.removeItem("user")
    window.location.href = "/";
    //window.location.reload(true);
}

//retrieve user stored information including JWT
const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"))
}

//mettre à jour mot de passe
const update = (ancien_pwd, nouveau_pwd, id_utilisateur) => {
    return axios.put(API_url + 'update/' + id_utilisateur, {
        old_password: ancien_pwd,
        password: nouveau_pwd
    });
}

const exports = {
    register,
    login,
    logout,
    getCurrentUser,
    update
}
export default exports