import React from "react";
//import ListeEmployes from "./ListeEmployes";
import Header from "./Header";
import Sidebar from './Sidebar'
import PageContent from './PageContent';

const Page = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <PageContent />
    </>
  );
};

export default Page;