import React, { useState, useEffect } from 'react'
import { ModalProfile } from './ModalProfile';
import AuthService from "../services/auth.service";
import { useForm } from 'react-hook-form'
import { Form, Button, Modal, FloatingLabel, Col, Row } from 'react-bootstrap'

const ModalResultatModificationPassword = (props) => {
    const [smShow, setSmShow] = useState(props.show);

    return <Modal
        size="sm"
        show={smShow}
        onHide={() => {
            setSmShow(false);
            props.handleClose();
        }}
        aria-labelledby="example-modal-sizes-title-sm"
    >
        <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">{props.titreModal && props.titreModal}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.message}</Modal.Body>
    </Modal>
}

const renderSwitch = (param, func) => {
    //console.log(param);
    switch (param) {
        case false://echec
            return <ModalResultatModificationPassword show={true} message="Modifications non enregistrées." titreModal="Erreur!" handleClose={func} />;
        case true://success
            return <ModalResultatModificationPassword show={true} message="Modifications sauvegardées avec succès. Vous devez vous reconnecter!" titreModal="Succès!" handleClose={func} />;
        default:
            //console.log("miditra default");
            return <ModalProfile show={true} />;
    }
}

export const ProfileInfo = (props) => {
    const [showModal, setShowModal] = useState(props.show)
    const [showSuccess, setShowSuccess] = useState(false)

    const handleClose = () => {
        setShowModal(false);
        //Dire à AgentProfileInfo qu je veux fermer le modal et déconnecter l'user
        props.handleClose()
        //props.show(false)
        //props.handleClose(true, true); //fermer et déconnecter l'user
    }

    const handleFermetureModalSucces = () => {
        AuthService.logout();
    }

    const handleShowModalResult = () => {
        //setSucces(true)
        //props.handleClose()
        setShowSuccess(true) //i want to display the success page
    }

    console.log(showModal);
    console.log(showSuccess);

    // on affiche le modal si showModal est true
    // on vérifie si on veut afficher un autre modal contenant un message d'erreur ou de succès si définit (showSuccess)
    return (<>{
        showModal && (showSuccess ? renderSwitch(showSuccess, handleFermetureModalSucces) : <ModalProfile show={showModal} handleClose={handleClose} showSuccess={handleShowModalResult} agent={props.agent} />)
    }</>)
}
