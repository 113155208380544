import React, { useEffect, useState } from 'react'

import PageHeader from "./PageHeader";
import PageSearchFilter from "./PageSearchFilter";
import ListeEmployes from './ListeEmployes';
import Alerte from './Alerte';

import AgentService from '../services/agent.service';
import ServiceService from '../services/service.service'
import AuthService from '../services/auth.service'

const PageContent = () => {
    const [personnels, setPersonnels] = useState([]);
    const [services, setServices] = useState([]);
    const [message, setMessage] = useState("");
    const [filtreIm, setFiltreIm] = useState("");
    const [filtreNom, setFiltreNom] = useState("");
    const [searchParam] = useState(["im", "nom", "prenom"]);
    const [filterParam, setFilterParam] = useState(["All"]);
    const user = AuthService.getCurrentUser()

    useEffect(() => {
        getListeEmployes();
        getServices();
    }, [])

    const handleSearchFilter = (args) => {
        try {
            setFiltreIm(args.im);
            setFiltreNom(args.nom);
            if (args.service !== 'All') {
                //console.log("autre que all sélectionné");
                setFilterParam(args.service)
            }
            else {
                setFilterParam("All")
            }
        } catch (error) {
            console.warn(error.message);
        }
    }

    const getServices = () => {
        try {
            ServiceService.getServices()
                .then((value) => {
                    //console.log(value);
                    setServices(value);
                });
        } catch (error) {
            console.warn(error.message);
        }
    }

    const getListeEmployes = () => {
        try {
            AgentService.getEmployes()
                .then((value) => {
                    //console.log(value);
                    if (value.hasOwnProperty('message')) {
                        setMessage(value.message)
                    } else {
                        var filteredData = value.filter(function (tous) {
                            //console.log(tous);
                            return tous.superieur_id !== null;
                        }).sort((a, b) => b.niveau - a.niveau);
                        setPersonnels(filteredData)
                        //setPersonnelsFiltres(filteredData)
                    }
                });
        } catch (error) {
            console.warn(error.message);
        }

    }

    /*const search = (items) => {
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(filtreIm.toLowerCase()) > -1
                );
            });
        });
    }*/

    function search(items) {
        return items.filter((item) => {
            if (item.id_service.toString() == filterParam) {
                return searchParam.some((newItem) => {
                    console.log(item[newItem]);
                    return (
                        item[newItem]
                            .toString()
                            .toLowerCase()
                            .indexOf(filtreIm.toLowerCase()) > -1
                    );
                });
            } else if (filterParam == "All") {
                return searchParam.some((newItem) => {
                    //console.log(newItem);
                    return (
                        item[newItem]
                            .toString()
                            .toLowerCase()
                            .indexOf(filtreIm.toLowerCase()) > -1
                    );
                });
            }
        });
    }

    console.log(user)
    return (
        <>
            <div class="page-wrapper">
                <div class="content container-fluid">
                    {message && (<Alerte variant="danger" message={message} />)}
                    {(user && user.roles.length > 1) ?
                        (
                            <>
                                <PageHeader title={"Liste des employés"} />
                                <PageSearchFilter handleSearchFilter={handleSearchFilter} services={services} />
                                {search(personnels).length !== 0 ? (<ListeEmployes personnels={search(personnels)} />) : (<Alerte variant="info" message="Aucun employé ne correspond à votre recherche." />)}
                            </>
                        ) : (<Alerte variant="danger" message="Accès refusé! Vous n'avez pas le droit de visualiser cette page." />)
                    }
                </div>
            </div>
        </>
    )
}

export default PageContent