import React, { useState, useEffect } from 'react'

import ModalDemande from './ModalDemande'
import ModalResultatDemande from './ModalResultatDemande';

const renderSwitch = (param, func) => {
    //console.log(param);
    switch (param) {
        case false://echec
            return <ModalResultatDemande show={true} message="Echec lors de l'enregistrement de votre demande." handleClose={func} />;
        case true://success
            return <ModalResultatDemande show={true} message="Demande envoyée avec succès." titreModal="Résultat de l'insertion" handleClose={func} />;
        default:
            //console.log("miditra default");
            return <ModalDemande show={true} />;
    }
}

const DemanderConge = (props) => {
    const [state, setState] = useState(props.show)
    const [showSuccess, setShowSuccess] = useState(props.showSuccess)
    
    const [decision, setDecision] = useState(props.stat)

    console.log(showSuccess);

    const handleClose = () => {
        setState(false);
        props.handleClose(false);
    }

    const handleShowModalResult = (result) => {
        setShowSuccess(result);
    }

    return (<>{
        state && (showSuccess !== undefined ? renderSwitch(showSuccess, () => handleClose()) : <ModalDemande show={state} handleClose={handleClose} showSuccess={handleShowModalResult} stat={decision} agent={props.agent} />)
    }</>)
}

export default DemanderConge
