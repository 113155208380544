import React, { useEffect, useState } from "react";
import Avatar from "./Avatar";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import ServiceService from "../services/service.service";
import AgentService from "../services/agent.service";
import AuthService from "../services/auth.service";
import { ProfileInfo } from "./ProfileInfo";

import fr from "date-fns/locale/fr";
const { format } = require("date-fns");


export const validateDate = (d) => {
  return Object.prototype.toString.call(Date.parse(d)) === "[object Date]";
};

export const displayDate = (date) => {
  let rep = "";
  console.log(validateDate(date));
  try {
    if (validateDate(date)) {
      var d = new Date();
      var dd = d.getDate();
      var mm = d.getMonth() + 1;
      var yyyy = d.getFullYear();
      rep = dd + "/" + mm + "/" + yyyy;
      return rep
    } else {
      console.warn("invalid date");
    }
  } catch (error) {
    console.warn(error.message);
  }
};

const AgentProfileInfo = (props) => {
  const [agent, setAgent] = useState("");
  const [show, setShow] = useState(false);// to toggle personal information modal
  const [success, setSuccess] = useState("");
  const [superieurAgent, setSuperieurAgent] = useState("");
  const [service, setService] = useState("");
  const userConnected = AuthService.getCurrentUser()

  useEffect(() => {
    //console.log(props);
    if (props.agent) {
      setAgent(props.agent);
      props.agent.superieur_id != null &&
        getAgentInfo(props.agent.superieur_id);
      getServiceFromId(props.agent.id_service);
    }
  }, [props.agent]);

  const getServiceFromId = (id) => {
    try {
      ServiceService.getServiceById(id).then((value) => {
        setService(value);
      });
    } catch (error) {
      console.warn(error.message);
    }
  };

  const getAgentInfo = (id) => {
    AgentService.getAgentById(id).then((data) => {
      if (data) {
        setSuperieurAgent(data);
      }
    });
  };

  const handleClose = () => {//to close profile edit modal
    setShow(false)
    /*if (close) {
      AuthService.logout();
    }*/
  };
  const handleShow = () => {
    //console.log("i want to open the modal");
    setShow(true);//to open profile edit modal
  }

  const showSuccess = (success) => {
    console.log(success);
    setSuccess(success)
  }

  return (
    <>
      {agent === "" ? (
        <p>Aucun agent trouvé</p>
      ) : (
        <div className="card mb-0">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="profile-view">
                  <div className="profile-img-wrap">
                    <div className="profile-img">
                      <a href="profile.html" className="avatar">
                        {agent.avatar && <Avatar url={agent.avatar} />}
                      </a>
                    </div>
                  </div>
                  <div className="profile-basic">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="profile-info-left">
                          <h3 className="user-name m-t-0 mb-0">
                            {agent.nom + " " + agent.prenom}
                          </h3>
                          {service && (
                            <h6 className="text-muted">
                              {service.nom_service &&
                                `Service: ${service.nom_service}`}
                            </h6>
                          )}
                          {agent.fonction && (
                            <small className="text-muted">
                              {agent.fonction}
                            </small>
                          )}
                          {agent.im && (
                            <div className="staff-id">
                              I.M. :{" "}
                              {agent.im.replace(/.{1,2}(?=(.{3})+$)/g, "$& ")}
                            </div>
                          )}

                          {/*agent.superieur_id !== null && (
                            <div className="staff-msg">
                              <Button
                                variant="primary"
                                onClick={() =>
                                  console.log("i want to edit my info")
                                }
                                className="btn-custom"
                              >
                                Modifier mes informations
                              </Button>
                            </div>
                              )*/}
                        </div>
                      </div>
                      <div className="col-md-7">
                        <ul className="personal-info">
                          {agent.email && (
                            <li>
                              <div className="title">Email:</div>
                              <div className="text">
                                <a href="#">{agent.email}</a>
                              </div>
                            </li>
                          )}
                          {agent.domicile && (
                            <li>
                              <div className="title">Adresse:</div>
                              <div className="text">{agent.domicile}</div>
                            </li>
                          )}
                          {agent.cin && (
                            <li>
                              <div className="title">CIN:</div>
                              <div className="text">
                                {agent.cin.replace(
                                  /.{1,2}(?=(.{3})+$)/g,
                                  "$& "
                                )}{" "}
                                {agent.cin_date_delivrance &&
                                  `du ${format(
                                    new Date(agent.cin_date_delivrance),
                                    "d MMMM yyyy",
                                    {
                                      locale: fr,
                                    }
                                  )}`}{" "}
                                {agent.cin_lieu_delivrance &&
                                  `à ${agent.cin_lieu_delivrance}`}
                              </div>
                            </li>
                          )}
                          {agent.date_naiss && (
                            <li>
                              <div className="title">Née le:</div>
                              <div className="text">
                                {format(
                                  new Date(agent.date_naiss),
                                  "d MMMM yyyy",
                                  {
                                    locale: fr,
                                  }
                                )}
                              </div>
                            </li>
                          )}
                          {agent.superieur_id !== null && (
                            <li>
                              <div className="title">Supérieur hiérarchique:</div>
                              <div className="text">
                                <div className="avatar-box">
                                  <div className="avatar avatar-xs">
                                    {superieurAgent.avatar && (
                                      <Avatar url={superieurAgent.avatar} />
                                    )}
                                  </div>
                                </div>
                                <Link
                                  to={`/profile/${superieurAgent.id_agent}`}
                                >
                                  {superieurAgent.nom +
                                    " " +
                                    superieurAgent.prenom}
                                </Link>
                              </div>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="pro-edit">
                    {/*restriction pour agent ihany */}
                    {(userConnected && userConnected.id_agent == agent.id_agent) &&
                      <Button onClick={handleShow}>
                        <i className="fa fa-pencil"></i>
                      </Button>}
                  </div>
                  {show && <ProfileInfo show={show} handleClose={handleClose} agent={agent} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AgentProfileInfo;
