import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import "../src/assets/css/bootstrap.min.css"
import "../src/assets/css/font-awesome.min.css"
import "../src/assets/css/line-awesome.min.css"
import "../src/assets/css/select2.min.css"
//import "../src/assets/css/bootstrap-datetimepicker.min.css"
import "../src/assets/css/style.css"

import App from './App';
//import ThemeSwitcher from './components/ThemeSwitcher';
const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <BrowserRouter>
    <App />    </BrowserRouter>
);