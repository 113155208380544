import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Row, Col, Table, Container } from "react-bootstrap";

import Header from "./Header";
import Sidebar from "./Sidebar";
import PageHeader from "./PageHeader";
import Alerte from './Alerte';

import { checkOdreDemande } from "./Header";

import DemandeService from "../services/demande.service";
import AuthService from "../services/auth.service";
import DemandeItem from "./DemandeItem";

const Demandes = () => {
  const { id } = useParams();
  const user = AuthService.getCurrentUser();
  const [demandesEnAttente, setDemande] = useState([]);
  const [
    demandesEnAttenteApresRegrouprementParId,
    setDemandesEnAttenteApresRegrouprementParId,
  ] = useState([]);
  const [idDemandeToValidate, setIdDemandeToValidate] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      getDemandeEnAttente();
    }, 1000);
    
    if (id > 0) {
      setIdDemandeToValidate(id);
    }
    //return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (id > 0) {
      setIdDemandeToValidate(id);
    }
  }, [id]);

  useEffect(() => {
    let tableauTemp_demandeDejaVerifie = [];
    let demandes = [];
    if (demandesEnAttente.length > 0) {
      //on regroupe les demandes par id_demande et statuts[]
      demandesEnAttente.forEach((element) => {
        if (tableauTemp_demandeDejaVerifie.indexOf(element.id_demande) === -1) {
          //pas dedans
          demandes.push({
            date_demande: element.date_demande,
            debut_conge: element.debut_conge,
            fin_conge: element.fin_conge,
            nb_jours: element.nb_jours,
            solde_avant: element.solde_avant,
            solde_apres: element.solde_apres,
            id_demande: element.id_demande,
            id_agent: element.id_agent,
            approbation: element.approbation,
            id_statut: element.id_statut,
          });
          tableauTemp_demandeDejaVerifie.push(element.id_demande);
        }
      });
    }

    if (tableauTemp_demandeDejaVerifie.length > 0) {
      tableauTemp_demandeDejaVerifie.forEach((id_demande) => {
        let toInsert = [];
        let temp_demandes = demandesEnAttente.filter(
          (o) => o.id_demande == id_demande
        );
        temp_demandes.forEach((element) => {
          toInsert.push({
            id_demande: element.id_demande,
            id_utilisateur: element.id_utilisateur,
            statut: element.statut,
            ordre: element.ordre,
            id_statut: element.id_statut,
          });
        });
        var index = demandes
          .map(function (e) {
            return e.id_demande;
          })
          .indexOf(toInsert[0].id_demande);
        if (index !== -1) {
          //id_demande retrouvé dans demandes
          demandes[index].statut = toInsert;
        }
      });
    }
    if (demandes.length > 0) {
      setDemandesEnAttenteApresRegrouprementParId(demandes);
    }
  }, [demandesEnAttente]);

  // get all demandes en attente
  const getDemandeEnAttente = () => {
    console.log("getDemandeEnAttente called");
    DemandeService.getAllDemandesEnattente()
      .then((data) => {
        setDemande(data);
      })
      .catch((err) => {
        console.warn(err.message);
      });
  };

  //check if userId a des demandes à valider
  const checkDemandesUserid = (demandes) => {
    let reponse = false
    if (user) {
      demandes.forEach((element) => {
        let findElement = element.statut.filter(
          (statut) => statut.id_utilisateur == user.id
        );
        if (findElement.length > 0) reponse = true
      });
    }
    return reponse
  };

  return (
    <>
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <Container fluid className="content">
          {(user.roles.length > 1) ?
            (
              <>
                <Row>
                  <Col sm={12}>
                    <PageHeader title={"Liste des demandes en attente"} />
                  </Col>
                </Row>
                {(demandesEnAttenteApresRegrouprementParId &&
                  demandesEnAttenteApresRegrouprementParId.length > 0 && checkDemandesUserid(demandesEnAttenteApresRegrouprementParId)) ? (
                  <Row>
                    <Col md={12}>
                      <Table
                        responsive
                        striped
                        className="custom-table mb-0 datatable"
                      >
                        <thead>
                          <tr>
                            <th>Demandeur</th>
                            <th>Date demande</th>
                            <th>Début </th>
                            <th>Fin</th>
                            <th>Nombres de jour demandés</th>
                            <th>Solde avant</th>
                            <th>Solde après</th>
                            <th>Statut</th>
                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {demandesEnAttenteApresRegrouprementParId.map((element) => (
                            <DemandeItem demande={element} userId={user.id} idDemandeToValidate={idDemandeToValidate} />
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                ) : (
                  <p>Aucune demande en attente</p>
                )}
              </>
            ) : (
              <Alerte variant="danger" message="Accès refusé! Seuls vos supérieurs ont droit de visualiser les demandes en attente." />
            )}

        </Container>
      </div>
    </>
  );
};

export default Demandes;