import React, { useState, useEffect } from 'react';

import SousCritereItem from './SousCritereItem';
import SouscritereService from '../services/souscritere.service';

const CritereItem = (props) => {
    //console.log(props);
    const item = props.critere
    const [souscriteres, setSousCriteres] = useState([])
    //console.log(item);
    useEffect(() => {
        SouscritereService.getSousCriteresByCritere(item.id_critere)
            .then((data) => {
                //console.log(agent);
                setSousCriteres(data);
            })
            .catch((err) => {
                console.warn(err.message);
            });
    }, [])

    //console.log(souscriteres);
    return (
        <>
            {souscriteres.length > 0 && (
                <>
                    <tr>
                        <td rowSpan={souscriteres.length + 1}>{item.id_critere}</td>
                        <td rowSpan={souscriteres.length + 1}>{item.libelle_critere}</td>
                    </tr>
                    {souscriteres.map(element => (
                        <SousCritereItem souscritere={element} key={element.id_souscritere} resultatEvaluation={props.resultatEvaluation} />
                    ))}
                </>
            )}
        </>

    )
}

export default CritereItem