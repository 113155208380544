import axios from "axios";
import { API_baseUrl } from './config';

//const API_url = "http://localhost:8082/api/statutdemande"
const API_url = API_baseUrl + "/statutdemande/"

//insérer statut demande
const create = (id_utilisateur, id_demande, ordre) => {
    let nouveauStatut = {}
    return axios.post(API_url, {
        id_utilisateur,
        id_demande,
        ordre
    })
        .then((response) => {
            if (response.hasOwnProperty("data")) {
                nouveauStatut = response.data
            }
            return nouveauStatut
        })
        .catch(error => {
            return error.response.data
        })
}

//update statut demande
const update = (id_statut) => {
    return axios.put(API_url + `/${id_statut}`, {
        statut: 1
    });
}


const exports = {
    create,
    update
}

export default exports