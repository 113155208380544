import axios from 'axios';
import { API_baseUrl } from './config';
//const API_url = "http://localhost:8082/api/services/"
const API_url = API_baseUrl + "/services/"
// get liste des services
const getServices = () => {
    return axios.get(API_url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            return error.response.data
        });
}

//get service by id
const getServiceById = (id) => {
    return axios.get(API_url + id)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            return error.response.data;
        });
}

const exports = {
    getServices,
    getServiceById
}

export default exports