import React from "react";
import { Link } from "react-router-dom";
import AuthService from '../services/auth.service'
import { Navigate } from 'react-router-dom'

const Sidebar = () => {
  const user = AuthService.getCurrentUser()
  const style = {
    display: "none",
  };
  //console.log(user);
  return (
    user ? (<div className="sidebar" id="sidebar">
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <ul>
            <li className="menu-title">
              <span>Menu principal</span>
            </li>
            <li className="submenu">
              <Link to={`/profile/${user.id_agent}`}>
                <i className="la la-user"></i> <span>Mon profil</span>{" "}
              </Link><Link to={`/calendrier`}>
                <i className="la la-calendar"></i> <span> Calendrier de congés</span>{" "}
              </Link>
              {user.roles.length > 1 && (
                <>
                  <Link to={`/demandes`}>
                    <i className="la la-check-square"></i> <span> Demandes à valider</span>{" "}
                  </Link>
                  <Link to={`/personnels`}>
                    <i className="la la-users"></i> <span> Liste des personnels</span>{" "}
                  </Link>
                  <Link to={`/evaluations`}>
                    <i className="fa fa-bar-chart"></i> <span> Evaluations enregistrées</span>{" "}
                  </Link>
                </>
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>) : (<Navigate to="/" />)
  );
};

export default Sidebar;
