import React, { useState, useEffect } from 'react'

import Header from "./Header";
import Sidebar from "./Sidebar";
import PageHeader from "./PageHeader";
import Alerte from './Alerte';

import SearchMoisConge from './SearchMoisConge';
import CalendrierMensuel from './CalendrierMensuel';

import DemandeService from '../services/demande.service'

export const lastday = function (y, m) {//renvoie le dernier jour du mois/année donné
  return new Date(y, m + 1, 0).getDate();
}

// composant affiche le calendrier de congés d'un mois/année donné
const CalendrierConges = () => {
  const [filtres, setFiltres] = useState([]) // les filtres de recherche:nom, mois, annee
  const [errorMsg, setErrorMsg] = useState([]) // message d'erreur à afficher en haut du calendrier
  const [lesDemandes, setLesDemandes] = useState([])

  //callback from searchmoisconge component
  const handleSearchFilter = (args) => {
    //je reçois les fitres de recherche de searchmoisconge: 
    /*{
    "nom": "",
    "mois": 3,
    "annee": 2022
    }*/
    setFiltres(args);
  }

  // récupération des congés de cette période
  useEffect(() => {
    if (filtres) {
      // vérification date inférieure à aujourd'hui
      if (filtres.annee && filtres.mois) {
        let validiteMoisAnnee = isMoisAnneeValid(filtres.mois, filtres.annee)
        if (validiteMoisAnnee) {
          // rechercher les congés
          setErrorMsg("")
          // récupérer date début et fin du mois sélectionné
          let dateDebutMois = filtres.annee + "-" + filtres.mois.toString().padStart(2, 0) + "-01"
          let dateFinMois = filtres.annee + "-" + filtres.mois.toString().padStart(2, 0) + "-" + lastday(filtres.annee, filtres.mois - 1)
          getConges(dateDebutMois, dateFinMois)
        } else {//afficher message d'erreur
          setErrorMsg("Mois/Année non valide car supérieur à aujourd\'hui")
        }
      }
    }
  }, [filtres])

  // vérification date inférieure à aujourd'hui = valide (true), 
  const isMoisAnneeValid = (mois, annee) => {
    const today = new Date()
    const moisCourant = today.getMonth() + 1 //mois courant
    const anneeCourante = today.getFullYear();//année courante
    return new Date(annee + "-" + mois) <= new Date(anneeCourante + "-" + moisCourant)
  }

  // récupération des congés durant une période donnée (mois-annee)
  const getConges = (dateDebut, dateFin) => {
    DemandeService.getAllDemandesApprouveesSelonPeriode(dateDebut, dateFin)
      .then(data => {
        setLesDemandes(data);
      })
      .catch(err => {
        console.warn(err.message);
      })
  }

  const mystyle = {
    position: 'sticky',
    top: 0,
    backgroundColor: 'yellow',
    padding: 50,
    fontSize: 20
  };

  //console.log(lesDemandes);
  return (
    <>
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          {errorMsg && <Alerte variant="danger" message={errorMsg} />}
          <PageHeader title={"Calendrier mensuel des congés"} />
          <SearchMoisConge className="mb-10" handleSearchFilter={(arg) => handleSearchFilter(arg)} />
          {/*<div style={mystyle}>I will stick to the screen when you reach my scroll position</div>*/}
          <CalendrierMensuel conges={lesDemandes} mois={filtres.mois} annee={filtres.annee} />
        </div>
      </div>
    </>
  )
}

export default CalendrierConges