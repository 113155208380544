import React, { useState, useEffect, useRef } from 'react'
import { Form, Button, Modal, FloatingLabel, Col, Row } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import Alerte from './Alerte'

import "react-datepicker/dist/react-datepicker.css";

import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";


import DemandeService from '../services/demande.service'
import AgentService from '../services/agent.service'
import StatutdemandeService from '../services/statutdemande.service'
import { subDays, format, addDays, addHours, subMinutes } from 'date-fns'
registerLocale("fr", fr);

export const getCadres = async () => {
    try {
        let cadres = await AgentService.getAgentCadres()
        return cadres

    } catch (error) {
        console.warn(error.message);
        throw error
    }
}

const ModalDemande = (props) => {
    const agent = props.agent
    const debut_today = new Date()
    debut_today.setHours(0, 0, 0);
    const fin_today = new Date()
    fin_today.setHours(23, 59, 0);

    const [state, setState] = useState(props.show)

    const [isSwitchOn, setIsSwitchOn] = useState(false);
    const [isSwitchOn2, setIsSwitchOn2] = useState(false);

    const [isDisabledInput, setIsDisabledInput] = useState(true);
    const [isDisabledInput2, setIsDisabledInput2] = useState(false);

    const [cadres, setCadres] = useState("")
    const [decisions, setDecisions] = useState(props.stat)
    const [nbdemande, setNbdemande] = useState("")
    const [nbrestant, setNbrestant] = useState("")

    const [dateDebut, setDateDebut] = useState(debut_today)
    const [dateFin, setDateFin] = useState(fin_today)

    const [dateDebutForinsert, setDateDebutForinsert] = useState(debut_today)
    const [dateFinForinsert, setDateFinForinsert] = useState(fin_today)
    const [insertResult, setInsertResult] = useState("")
    const [compteurDemande, setCompteurDemande] = useState(0)

    const { register, control, setValue, handleSubmit, formState: { errors } } = useForm()

    const handleClose = () => {
        setState(false);
        props.handleClose(false);
    }

    useEffect(() => {
        if (decisions.length > 0 && nbrestant === "") {
            setNbdemande(1);
        }
        setValue("date_debut", dateDebut)
        setValue("date_fin", dateFin)
        setValue("nb_restant", nbrestant)
        setValue("nb_demander", nbdemande)
        getCadres()
            .then(data => {
                setCadres(data)
            })

    }, [])

    useEffect(() => {
        try {
            if (Number(nbrestant)) {
                setValue("nb_restant", nbrestant)
            }
        } catch (error) {
            console.warn(error.message);
        }
    }, [nbrestant])

    useEffect(() => {
        if (insertResult != "") {
            props.showSuccess(insertResult)
        }
    }, [insertResult])

    useEffect(() => {
        try {
            if (nbdemande !== "") {
                setValue("nb_demander", nbdemande)
                setNbrestant(getTotalCongesRestants() - Number(nbdemande))
            }
        } catch (error) {
            console.warn(error.message);
        }
    }, [nbdemande])

    useEffect(() => {
        try {
            const date_tmp_debut = dateDebut
            date_tmp_debut.setHours(0, 0, 0)
            const date_tmp_fin = dateFin
            date_tmp_fin.setHours(0, 0, 0)
            if (date_tmp_debut.valueOf() == date_tmp_fin.valueOf()) {
                if (isSwitchOn == true) setIsSwitchOn(false);
                setIsDisabledInput(true)
                setValue("date_debut", dateDebut)
                setValue("date_fin", dateDebut)
            } else if (dateDebut.valueOf() > dateFin.valueOf()) {
                setIsDisabledInput(false)
                setDateDebut(dateDebut)
                setDateFin(dateDebut)
                if (isSwitchOn) {
                    setIsSwitchOn(false);
                    //console.log(new Date(dateDebut.getFullYear(), dateDebut.getMonth(), dateDebut.getDate(), 12, 0, 0));
                    setDateDebutForinsert(new Date(dateDebut.getFullYear(), dateDebut.getMonth(), dateDebut.getDate(), 12, 0, 0))
                }
                if (!isSwitchOn) setDateDebutForinsert(new Date(dateDebut.getFullYear(), dateDebut.getMonth(), dateDebut.getDate(), 0, 0, 0))
                if (isSwitchOn2) setDateFinForinsert(new Date(dateFin.getFullYear(), dateFin.getMonth(), dateFin.getDate(), 11, 59, 0))
                if (!isSwitchOn2) setDateFinForinsert(new Date(dateFin.getFullYear(), dateFin.getMonth(), dateFin.getDate(), 23, 59, 0))
                setValue("date_debut", dateDebut)
                setValue("date_fin", dateFin)
            } else {
                setIsDisabledInput(false)
                setDateDebut(dateDebut)
                setDateFin(dateFin)
                if (isSwitchOn) {
                    //console.log(new Date(dateDebut.getFullYear(), dateDebut.getMonth(), dateDebut.getDate(), 12, 0, 0));
                    setDateDebutForinsert(new Date(dateDebut.getFullYear(), dateDebut.getMonth(), dateDebut.getDate(), 12, 0, 0))
                }
                if (!isSwitchOn) setDateDebutForinsert(new Date(dateDebut.getFullYear(), dateDebut.getMonth(), dateDebut.getDate(), 0, 0, 0))
                if (isSwitchOn2) setDateFinForinsert(new Date(dateFin.getFullYear(), dateFin.getMonth(), dateFin.getDate(), 11, 59, 0))
                if (!isSwitchOn2) setDateFinForinsert(new Date(dateFin.getFullYear(), dateFin.getMonth(), dateFin.getDate(), 23, 59, 0))
                setValue("date_debut", dateDebut)
                setValue("date_fin", dateFin)
            }
            setNbdemande(getDifferenceEnJours(dateDebut.getDate(), dateDebut.getMonth(), dateDebut.getFullYear(), dateFin.getDate(), dateFin.getMonth(), dateFin.getFullYear(), isSwitchOn, isSwitchOn2).toFixed(2));
        } catch (error) {
            console.warn(error.message);
        }
    }, [dateDebut, dateFin, isDisabledInput, isSwitchOn, isSwitchOn2, nbdemande])

    const getDifferenceEnJours = (date1_jour, date1_mois, date1_annee, date2_jour, date2_mois, date2_annee, demijournee_date1, demijournee_date2) => {
        var d1 = new Date(date1_annee, date1_mois, date1_jour);
        d1.setHours(0, 0, 0);
        var d2 = new Date(date2_annee, date2_mois, date2_jour);
        d2.setHours(23, 59, 0);

        if (demijournee_date1) { //demi-journée: 12:00:00
            d1 = new Date(date1_annee, date1_mois, date1_jour);
            d1.setHours(12, 0, 0);
        }
        if (demijournee_date2) { //demi-journée: 12:00:00
            d2 = new Date(date2_annee, date2_mois, date2_jour);
            d2.setHours(11, 59, 0);
        }

        // To calculate the time difference of two dates
        var Difference_In_Time = d2.getTime() - d1.getTime();

        // To calculate the no. of days between two dates
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

        return Difference_In_Days
    }

    //Date 1(début): 06/12/2021; demijournee_date1(début): false; demijournee_date2(fin): false;nbjours=5.5 
    //>>> intervalle [début,fin] = 
    const getIntervalleFromJours = (dateDebut, nbJours) => {
        let dateFin;
        if (!Number.isInteger(nbJours)) {
            dateFin = addDays(dateDebut, nbJours);
            return addHours(dateFin, 12)
        } else {
            dateFin = addDays(dateDebut, nbJours);
        }
        return dateFin
    }

    const getTotalCongesRestants = () => {
        try {
            const totalRestants = decisions.reduce((a, b) => +a + +b.conges_restants, 0)
            return totalRestants;
        } catch (error) {
            console.warn(error.message);
        }
    }

    //retourne un tableau d'objet contenant les décisions de congés après maj des dates pour chaque décision
    const updateDecisionCongeSelonNbjoursdemandes = (decision, nbjours, dateDebut, dateFin) => {
        try {
            let nbjrs = 0; //compteur nbjrs, si on a atteint le nbjours requis (donné en paramètre) on cesse de rechercher d'autres décisions
            let nbrestant = nbjours;
            let lesDecisions = [];
            let datedebut_temp = dateDebut;
            console.log(datedebut_temp);
            //result contient les décisions ayant encore des conges_restants positifs
            const result = decision.filter(periode => periode.conges_restants > 0);

            for (var i = 0; i < result.length; i++) {
                let periode = result[i];
                if ((periode.conges_restants - nbrestant) < 0) {
                    let d = getIntervalleFromJours(datedebut_temp, periode.conges_restants)

                    let decision_temp = {
                        id_decision: periode.id_decision,
                        periode: periode.periode,
                        congespris: periode.conges_restants,
                        date_debut: datedebut_temp,
                        date_fin: subMinutes(d, 1),
                        restants: 0
                    }
                    nbjrs += periode.conges_restants
                    lesDecisions.push(decision_temp)
                    nbrestant = nbrestant - periode.conges_restants
                    datedebut_temp = d
                } else {
                    let decision_temp = {
                        id_decision: periode.id_decision,
                        periode: periode.periode,
                        congespris: nbrestant,
                        date_debut: datedebut_temp,
                        date_fin: subMinutes(getIntervalleFromJours(datedebut_temp, nbrestant), 1),
                        restants: (periode.conges_restants - nbrestant)
                    }
                    lesDecisions.push(decision_temp);
                    nbjrs += nbrestant
                    if (nbjrs === nbjours) return lesDecisions;
                }
            }
            return lesDecisions;
        } catch (error) {
            console.warn(error.message);
        }
    }

    const getListeUtilisateursAnotifier = (id, superieur_id, niveau) => {
        let lesUtilisateurs = []
        let raf, cg, sgp
        if (cadres != "") {
            let temp_raf = cadres.filter(agent => agent.abbr_fonction === 'RAF')
            if (temp_raf.length > 0) {
                raf = temp_raf[0]
            }
            temp_raf = cadres.filter(agent => agent.abbr_fonction === 'CG')
            if (temp_raf.length > 0) {
                cg = temp_raf[0]
            }
            temp_raf = cadres.filter(agent => agent.abbr_fonction === 'SGP')
            if (temp_raf.length > 0) {
                sgp = temp_raf[0]
            }
            if (raf && id === raf.id_agent)//si demandeur RAF, inclure CG
            {
                lesUtilisateurs.push({
                    id_utilisateur: cg.id_utilisateur,
                    statut: 0,
                    ordre: 1
                })
                //return lesUtilisateurs
            } else {//autre que RAF
                if (cg && id == cg.id_agent) { //si demandeur CG, inclure RAF et SGP
                    lesUtilisateurs = []
                    lesUtilisateurs.push({
                        id_utilisateur: raf.id_utilisateur,
                        statut: 0,
                        ordre: 1
                    }, {
                        id_utilisateur: sgp.id_utilisateur,
                        statut: 0,
                        ordre: 2
                    })
                    //return lesUtilisateurs
                }
                else if (niveau === raf.niveau) // rang des directeurs PRMP,COACHAP,DPSE => inclure RAF et CG
                {
                    lesUtilisateurs = []
                    lesUtilisateurs.push({
                        id_utilisateur: raf.id_utilisateur,
                        statut: 0,
                        ordre: 1
                    }, {
                        id_utilisateur: cg.id_utilisateur,
                        statut: 0,
                        ordre: 2
                    })
                    //return lesUtilisateurs
                } else {//simple agent, inclure RAF,supérieur hiérarchique,CG
                    if (superieur_id == raf.id_agent)//superieur RAF, inclure CG
                    {
                        lesUtilisateurs = []
                        lesUtilisateurs.push({
                            id_utilisateur: raf.id_utilisateur,
                            statut: 0,
                            ordre: 1
                        }, {
                            id_utilisateur: cg.id_utilisateur,
                            statut: 0,
                            ordre: 2
                        })
                        //return lesUtilisateurs
                    } else if (superieur_id == cg.id_agent) {//superieur CG, inclure RAF en premier après CG
                        lesUtilisateurs = []
                        lesUtilisateurs.push({
                            id_utilisateur: raf.id_utilisateur,
                            statut: 0,
                            ordre: 1
                        }, {
                            id_utilisateur: cg.id_utilisateur,
                            statut: 0,
                            ordre: 2
                        })
                        //return lesUtilisateurs
                    } else {//supérieur autre que RAF et CG, inclure RAF, supérieur, CG
                        lesUtilisateurs = []
                        temp_raf = cadres.filter(agent => agent.id_agent === superieur_id)
                        if (temp_raf.length > 0) {
                            let superieur = temp_raf[0]
                            lesUtilisateurs.push({
                                id_utilisateur: superieur.id_utilisateur,
                                statut: 0,
                                ordre: 2
                            })
                        }
                        lesUtilisateurs.push({
                            id_utilisateur: raf.id_utilisateur,
                            statut: 0,
                            ordre: 1
                        }, {
                            id_utilisateur: cg.id_utilisateur,
                            statut: 0,
                            ordre: 3
                        })
                        //return lesUtilisateurs
                    }
                }
            }
        }
        return lesUtilisateurs
    }

    //exécuter après clic sur bouton envoyer formulaire
    const onSubmit = data => {
        let reponse
        let motif_demande = ""
        let compteur = 0
        let pj = ""
        let date_demande = new Date()
        try {
            //handleClose();
            //console.log(data);
            if (decisions.length > 0) {
                if (data.hasOwnProperty("motif")) {
                    motif_demande = data.motif
                }
                console.log(dateDebutForinsert);
                const tableauDecision = updateDecisionCongeSelonNbjoursdemandes(decisions, Number(data.nb_demander), dateDebutForinsert, dateFinForinsert)
                if (tableauDecision.length > 0) {
                    //console.log(tableauDecision);
                    let utilisateursAnotifier = getListeUtilisateursAnotifier(agent.id_agent, agent.superieur_id, agent.niveau)
                    setCompteurDemande(tableauDecision.length * utilisateursAnotifier.length)
                    tableauDecision.map(decision => {
                        if (DemandeService) {
                            console.log(decision.date_debut + " " + decision.date_fin)
                            DemandeService.create(decision.date_debut, decision.date_fin, motif_demande, decision.id_decision, (decision.restants + decision.congespris), decision.congespris)
                                .then(nouvelleDemande => {
                                    console.log(nouvelleDemande)
                                    if (utilisateursAnotifier.length > 0) {
                                        utilisateursAnotifier.map(user => {
                                            StatutdemandeService.create(user.id_utilisateur, nouvelleDemande.id_demande, user.ordre)
                                                .then(data => {
                                                    if (data) {
                                                        compteur++
                                                        if (compteur == tableauDecision.length * utilisateursAnotifier.length) {
                                                            setInsertResult(true)
                                                        }
                                                    }
                                                })
                                                .catch(err => {
                                                    console.warn(err.message);
                                                })
                                        })

                                        //temp_statut_demande = StatutdemandeService.create()
                                    }
                                })
                                .catch(err => {
                                    console.warn(err.message);
                                })
                        }
                    })
                } else {
                    return reponse
                }
            }
            //props.showSuccess(true)//true si afficher modal success; false: si afficher erreur insertion
        } catch (error) {
            console.warn(error.message);
            return reponse
        }
    }

    return <Modal show={state} onHide={handleClose} className="custom-modal" centered>
        <Modal.Header closeButton>
            <Modal.Title>Formulaire de demande de congé</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                {/*<div class="form-group">
            <label>Leave Type <span class="text-danger">*</span></label>
            <select class="select">
                <option>Select Leave Type</option>
                <option>Casual Leave 12 Days</option>
                <option>Medical Leave</option>
                <option>Loss of Pay</option>
            </select>
            </div>*/}
                <Row className="mb-3 align-items-center">
                    <Form.Group as={Col} controlId="formDebutConge" sm={8}>
                        <Form.Label>Du</Form.Label>
                        <Controller
                            control={control}
                            name="date_debut"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <DatePicker
                                    className="input"
                                    onChange={
                                        date => {
                                            setDateDebut(date)
                                        }
                                    }
                                    onBlur={onBlur}
                                    selected={value}
                                    locale="fr"
                                    //minDate={subDays(new Date(), 0)}
                                    dateFormat="d MMMM yyyy"
                                    todayButton="Aujourd'hui"
                                />
                            )}
                            rules={{ required: 'Veuillez sélectionner la date de début' }}
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword" sm={4}>
                        <Form.Check
                            custom
                            type="switch"
                            id="custom-switch-1"
                            label="demi-journée"
                            checked={isSwitchOn}
                            disabled={isDisabledInput}
                            className="mt-4"
                            {...register("debut_demi")}
                            onChange={() => setIsSwitchOn(!isSwitchOn)}
                        />
                    </Form.Group>
                    {errors.date_debut && <Alerte className="mt-3" variant="danger" message={errors.date_debut.message} />}
                </Row>

                <Row className="mb-3 align-items-center">
                    <Form.Group as={Col} controlId="formFinConge" sm={8}>
                        <Form.Label>Au</Form.Label>
                        <Controller
                            control={control}
                            name="date_fin"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <DatePicker
                                    onChange={
                                        date => {
                                            setDateFin(date)
                                        }
                                    }
                                    onBlur={onBlur}
                                    selected={value}
                                    locale="fr"
                                    minDate={dateDebut}
                                    dateFormat="d MMMM yyyy"
                                />
                            )}
                            rules={{ required: 'Veuillez sélectionner la date de fin' }}
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword" sm={4}>
                        <Form.Check
                            custom
                            type="switch"
                            id="custom-switch-1"
                            label="demi-journée"
                            checked={isSwitchOn2}
                            disabled={isDisabledInput2}
                            className="mt-4"
                            {...register("debut_demi")}
                            onChange={() => setIsSwitchOn2(!isSwitchOn2)}
                        />
                    </Form.Group>
                    {errors.date_fin && <Alerte className="mt-3" variant="danger" message={errors.date_fin.message} />}
                </Row>
                <Form.Group className="mb-3" controlId="formNbjoursDemande">
                    <Form.Label>Nombre de jours de congé demandé</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder=""
                        readOnly
                        {...register("nb_demander")}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formNbjoursRestant">
                    <Form.Label>Nombre de jours de congé restant</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder=""
                        {...register("nb_restant")}
                        readOnly />
                </Form.Group>

                <FloatingLabel
                    controlId="floatingTextareaMotif"
                    label="Motif"
                    className="mb-3"
                >
                    <Form.Control
                        as="textarea"
                        placeholder="Motif"
                        {...register("motif")} />
                </FloatingLabel>
            </Form>
        </Modal.Body>
        <Modal.Footer className="submit-section">
            <Button variant="primary" onClick={handleSubmit(onSubmit)} className="submit-btn">
                Envoyer demande
            </Button>
        </Modal.Footer>
    </Modal>
}

export default ModalDemande