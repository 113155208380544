import React from 'react'
import { Alert } from 'react-bootstrap'

const Alerte = (props) => {
    return (
        <div className="mt-1">
            <Alert variant={props.variant}>
                {props.message}
            </Alert>
        </div>
    )
}

export default Alerte
