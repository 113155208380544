import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form, Button, Modal, FloatingLabel, Col, Row } from 'react-bootstrap'

const ModalResultatDemande = (props) => {
    const [smShow, setSmShow] = useState(props.show);
    const [titre, setTitre] = useState(props.titreModal)

    return <Modal
        size="sm"
        show={smShow}
        onHide={() => {
            setSmShow(false);
            props.handleClose();
        }}
        aria-labelledby="example-modal-sizes-title-sm"
    >
        <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">{titre && titre}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.message}</Modal.Body>
    </Modal>
}

export default ModalResultatDemande
