import React, { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import Page from './Page'
import Profile from './Profile'

import AuthService from '../services/auth.service'
import CalendrierConges from './CalendrierConges'

const Home = () => {
    const user = AuthService.getCurrentUser()

    const [currentUser, setCurrentUser] = useState(user)
    //const [isAdmin, setIsAdmin] = useState(false)

    useEffect(() => {
        if (user) {
            setCurrentUser(user)
            //setIsAdmin(user.roles.includes("ROLE_ADMIN"))
        }
    }, [user])

    //console.log(user);
    return (
        currentUser ? (<CalendrierConges />) : (<Navigate to="/" />)
    )
}
export default Home