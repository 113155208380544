import axios from 'axios';
import { API_baseUrl } from './config';

//const API_url = "http://localhost:8082/api/evaluation/"
const API_url = API_baseUrl + "/evaluation/"

//insérer une nouvelle évaluation par sous-critère
const create = (id_agent, id_souscritere, id_resultateval, observation_notation, point_obtenu) => {
    let nouvelleEvaluation = {}
    return axios.post(API_url, {
        id_agent,
        id_souscritere,
        id_resultateval,
        observation_notation,
        point_obtenu
    })
        .then((response) => {
            if (response.hasOwnProperty("data")) {
                nouvelleEvaluation = response.data
            }
            return nouvelleEvaluation
        })
        .catch(error => {
            return error.response.data
        })
}

// get evaluation by id
const getEvaluation = (id) => {
    return axios.get(API_url + id)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        });
}

const exports = {
    create,
    getEvaluation
}

export default exports