import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Login from './components/Login';
import Home from './components/Home';
import Demandes from './components/Demandes';
import BoardUser from './components/BoardUser';
import BoardAdmin from './components/BoardAdmin';
import Profile from './components/Profile';
import Page404 from './components/Page404';
import CalendrierConges from './components/CalendrierConges';
import Evaluation from './components/Evaluation';
import Evaluations from './components/Evaluations';
import ViewEvaluation from './components/ViewEvaluation';
import ExporterEvaluation from './components/ExporterEvaluation';
import Page from './components/Page';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/home" element={<Home />} />
      <Route path="/user" element={<BoardUser />} />
      <Route path="/admin" element={<BoardAdmin />} />
      <Route path="/profile/:id" element={<Profile />} />
      <Route path="/personnels" element={<Page />} />
      <Route path="/demandes/:id" element={<Demandes />} />
      <Route path="/demandes" element={<Demandes />} />
      <Route path="/calendrier" element={<CalendrierConges />} />
      <Route path="/evaluation/:id" element={<Evaluation />} />
      <Route path="/evaluation/view/:id" element={<ViewEvaluation />} />
      <Route path="/evaluations" element={<Evaluations />} />
      <Route
        path="*"
        element={
          <Page404 />
        }
      />
    </Routes>
  );
}

export default App;