import axios from "axios";
import { API_baseUrl } from './config';
//const API_url = "http://localhost:8082/api/demande"
const API_url = API_baseUrl + "/demande"

//insérer une nouvelle demande de demande
const create = (debut, fin, motif, id_decision, solde_avant, nb_jours) => {
    let nouvelleDemande = {}
    //console.log(debut);
    //console.log(fin);
    return axios.post(API_url, {
        debut,
        fin,
        motif,
        id_decision,
        solde_avant,
        nb_jours
    })
        .then((response) => {
            if (response.hasOwnProperty("data")) {
                nouvelleDemande = response.data
            }
            return nouvelleDemande
        })
        .catch(error => {
            return error.response.data
        })
}

//get dernière demande envoyée par un agent
const getLastDemande = (id) => {
    return axios.get(API_url + '/last/' + id)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.log(error);
            return error.response.data
        })
}

// demandes de congé en attente d'approbation par utilisateur ayant id
const getDemandesEnattente = (id) => {
    return axios.get(API_url + '/attente/' + id)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.log(error);
            return error.response.data
        })
}

// demandes de congé en attente d'approbation
const getAllDemandesEnattente = () => {
    return axios.get(API_url + '/attente')
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.log(error);
            return error.response.data
        })
}

// approuver demande
const approuverDemande = (idDemande) => {
    return axios.put(API_url + `/${idDemande}`, {
        approbation: 1
    });
}


// récupérer toutes les demandes approuvées dans un mois/année donné
//débutMois:2022-02-01,finMois:"2022-02-28"
const getAllDemandesApprouveesSelonPeriode = (debutMois, finMois) => {
    return axios.post(API_url + '/all', {
        dateDebutMois: debutMois,
        dateFinMois: finMois
    })
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.log(error);
            return error.response.data
        })
}

const exports = {
    create,
    getLastDemande,
    getDemandesEnattente,
    getAllDemandesEnattente,
    approuverDemande,
    getAllDemandesApprouveesSelonPeriode
}

export default exports