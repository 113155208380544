import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { Row, Col, Container } from 'react-bootstrap';
import { Navigate } from 'react-router-dom'

import Header from './Header';
import Sidebar from './Sidebar';
import PageHeader from './PageHeader';
import AgentProfileInfo from './AgentProfileInfo';

import AuthService from '../services/auth.service'
import AgentService from '../services/agent.service';
import DemandeService from '../services/demande.service';

import StatsConges from './StatsConges';
import StatDecisions from './StatDecisions';
import DemandeRecents from './DemandeRecents';
import Alerte from './Alerte';

const Profile = () => {
    const { id } = useParams()
    const [idagent, setIdagent] = useState(id)
    const [agent, setAgent] = useState("");
    const [statagent, setStatagent] = useState([])
    const [dernieredemande, setDernieredemande] = useState("")
    const user = AuthService.getCurrentUser()

    useEffect(() => {
        getAgentInfo();
        getStatAgent();
        getDerniereDemandeAgent();
    }, [idagent])

    useEffect(() => {
        setIdagent(id)
    }, [id])

    const getAgentInfo = () => {
        //console.log(idagent);
        AgentService.getAgentById(idagent)
            .then(data => {
                if (data) {
                    //console.log(data);
                    setAgent(data)
                }
            });
    }

    const getStatAgent = () => {
        AgentService.getAgentDecisions(idagent)
            .then((data) => {
                setStatagent(data);
            })
    }

    const getDerniereDemandeAgent = () => {
        DemandeService.getLastDemande(idagent)
            .then(data => {
                setDernieredemande(data)
            })
    }

    //console.log(user);
    //console.log(idagent);

    return (
        <>
            {((user && idagent && (user.roles.length > 1 || idagent == user.id_agent))) ? (
                <>
                <Header />
                <Sidebar />
                <div className="page-wrapper">
                    <Container fluid className="content">
                        <Row><Col sm={12}><PageHeader title={"Profil"} /></Col></Row>
                        <Row>{agent !== "" ?
                            (<>
                                <Col sm={12}>
                                    <AgentProfileInfo agent={agent} />
                                </Col></>)
                            : <p>Agent non trouvé</p>}</Row>
                        <Row className="mt-3">{(agent !== "" && statagent.length > 0) ?
                            (<>
                                <Col sm><StatDecisions id={agent.id_agent} stat={statagent} /></Col>
                                <Col sm><StatsConges agent={agent} stat={statagent} /></Col>
                                <Col sm><DemandeRecents demande={dernieredemande} /></Col>
                            </>)
                            : <Alerte variant="info" message="Aucune statistique de congé disponible." />}</Row>
                    </Container>
                </div>
            </>
            ) : (<Navigate to="/" />)}
        </>
    )
}
export default Profile