import React, { useState, useEffect } from "react"
import { AgentInfo } from './DemandeItem';
import { formatDate } from './../utility/Datefunction';
import { Link } from 'react-router-dom'

const EvaluationItem = (props) => {
    const evaluation = props.evaluation
    //getting superieur_id de l'agent évalué from AgentInfo
    const [superieurAgentID, setSuperieurAgentID] = useState(0)
    const [agents, setAgents] = useState(0)

    const gettingSuperieurIdFromAgentInfo = (id) => {
        //console.log("nisy niantso");
        setSuperieurAgentID(id)
    }

    //console.log(evaluation);
    //console.log(superieurAgentID);

    useEffect(() => {

    }, [])

    useEffect(() => {
        if (superieurAgentID > 0) {
            // récupération info supérieur
        }
    }, [superieurAgentID])

    return (
        <>
            <tr>
                <td>
                    {evaluation.date_evaluation && formatDate(new Date(evaluation.date_evaluation))}
                </td>
                <td>
                    {evaluation.agent_evaluer && (<AgentInfo id={evaluation.agent_evaluer} sendingAgentdata={gettingSuperieurIdFromAgentInfo} />)}
                </td>
                <td>
                    {superieurAgentID && (<AgentInfo id={superieurAgentID} />)}
                </td>
                <td>
                    {evaluation.total_note}
                </td>
                <td>
                    {evaluation.observation_generale}
                </td>
                <td>
                    <Link to={`/evaluation/view/${evaluation.id_resultateval}`}><i class="fa fa-eye m-r-5"></i> Voir</Link>
                    <br/>
                    <Link to={`/evaluation/export/${evaluation.id_resultateval}`}><i class="fa fa-check m-r-5"></i>Valider</Link>
                </td>
            </tr>
        </>
    )
}

export default EvaluationItem