import React, { useState, useEffect } from "react";
import { Row, Col, FloatingLabel, Form, Button } from 'react-bootstrap';

const PageSearchFilter = (props) => {
  const initialState = {
    im: "",
    nom: "",
    service: "All"
  }
  const [state, setState] = useState(initialState)

  const effacerFiltres = (e) => {
    e.preventDefault();
    //console.log("submitted");
    setState(initialState);
  }

  const handleInputChange = (e) => {
    const value = e.target.value;
    setState({ ...state, [e.target.name]: value })
  }

  const handleSelect = (e) => {
    //console.log(e.target.value);
    const value = e.target.value;
    if (value !== "All") {
      setState({ ...state, ["service"]: value });
    }
    else {
      setState({ ...state, service: "All" });
    }
  }

  useEffect(() => {
    props.handleSearchFilter(state)
    //setState({service:"All"})
  }, [state])
  /*console.log("im"+state.im);
  console.log("nom"+state.nom);*/

  return (
    <>
      <Form>
        <Row className="filter-row">
          <Col sm={6} md={3}>
            <FloatingLabel controlId="floatingInputGrid" label="Numéro Matricule">
              <Form.Control
                type="text"
                placeholder="Numéro Matricule"
                onChange={handleInputChange}
                value={state.im}
                name="im" />
            </FloatingLabel>
          </Col>
          <Col sm={6} md={3}>
            <FloatingLabel controlId="floatingInputGrid" label="Nom de l'employé">
              <Form.Control
                type="text"
                placeholder="Nom de l'employé"
                name="nom"
                onChange={handleInputChange}
                value={state.nom} />
            </FloatingLabel>
          </Col>
          <Col sm={6} md={3}>
            <FloatingLabel controlId="floatingSelectGrid" label="Service" >
              <Form.Select aria-label="Service" onChange={handleSelect}>
                <option value="All">Tous les services</option>
                {props.services.filter(function (tous) {
                  //console.log(tous);
                  return tous.id_service !== 1;
                }).map(service => <option key={service.id_service} value={service.id_service}>{service.nom_service}</option>)}
              </Form.Select>
            </FloatingLabel>
          </Col>
          <Col className="d-grid gap-1" sm={6} md={3}>
            <Button variant="success" size="lg" onClick={effacerFiltres}>
              Effacer filtres
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default PageSearchFilter;
