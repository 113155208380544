import React, { useState, useEffect } from 'react'
import { ProgressBar } from 'react-bootstrap'

const StatDecisionItem = (props) => {
    const variantArray = ["success", "info", "danger", "warning"]
    const [max, min] = [0, variantArray.length]

    //get random int 
    const getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    }

    //get percentage
    const getPercentage = (number1, number2) => {
        return Math.floor(100 * number1 / number2)
    }

    return (
        <>
            {props.hasOwnProperty('stat') ? (
                <div className="stats-info">
                    <p>{props.stat.periode} <strong>{props.stat.conges_pris} <small>/ {props.stat.solde_conges}</small></strong></p>
                    <ProgressBar now={getPercentage(props.stat.conges_pris, props.stat.solde_conges)} label={getPercentage(props.stat.conges_pris, props.stat.solde_conges) + '%'} variant={variantArray[getRandomInt(min, max)]} visuallyHidden />
                </div>
            ) : (console.log("non"))}
        </>
    )
}

export default StatDecisionItem