import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';

import Header from "./Header";
import Sidebar from "./Sidebar";
import PageHeader from "./PageHeader";
import Alerte from './Alerte'
import CritereItem from "./CritereItem";

import { getCriteres, getSousCriteres } from './Evaluation';

import AuthService from "../services/auth.service";
import { getAgentInfo } from "./Header";

import { Form, Row, Table, Button, Col, Container } from "react-bootstrap";
import { useForm, FormProvider } from 'react-hook-form'

import ResultatEvaluation from '../services/resultat_evaluation.service';

import { formatDate } from './../utility/Datefunction';
import image_entete from '../assets/img/entete.JPG';

import HTMLtoDOCX from 'html-to-docx';
import { saveAs } from 'file-saver';
import { DocumentCreator } from './DocumentCreator'
import { getResultats } from './SousCritereItem';

const docx = require('docx');
const { Document,
    ImageRun,
    Media,
    TableCell, TableRow, WidthType, SectionType,
    Packer,
    Paragraph,
    VerticalAlign,
    BorderStyle,
    convertInchesToTwip,
    TextRun } = docx;

const ViewEvaluation = () => {
    const { id } = useParams() //id resultat evaluation à afficher
    const viewer = AuthService.getCurrentUser();
    const [viewerInfo, setViewerInfo] = useState([])

    //console.log(id);
    const [resultatEval, setResultatEval] = useState([])
    const [totalBareme, setTotalBareme] = useState(0)
    const [totalEval, setTotalEval] = useState(0)
    const [agent, setAgent] = useState("")
    const [souscriteres, setSousCriteres] = useState([])
    const [criteres, setCriteres] = useState([])

    const methods = useForm({
        defaultValues: {
            totalEval: '0'
        }
    });
    const { register, setValue } = methods;

    useEffect(() => {
        getResultat_Evaluation(id)
        getCriteres()
            .then(data => {
                //console.log(data);
                setCriteres(data)
            })
        getSousCriteres()
            .then(data => {
                //console.log(data);
                setSousCriteres(data)
            })
        if (viewer) {
            getAgentInfo(viewer.id_agent)
                .then((agent) => {
                    //console.log(agent);
                    setViewerInfo(agent);
                })
                .catch((err) => {
                    console.warn(err.message);
                });
        }
    }, []);

    useEffect(() => {
        //resultat evaluation trouvée?
        if (resultatEval.length > 0) {
            //console.log(resultatEval);
            //affichage des notes et observations dans chaque champ
            //total note évaluation
            setTotalEval(resultatEval[0].total_note)
            if (resultatEval[0].agent_evaluer) {
                getAgentInfo(resultatEval[0].agent_evaluer)
                    .then((agent) => {
                        //console.log(agent);
                        setAgent(agent);
                    })
                    .catch((err) => {
                        console.warn(err.message);
                    });
            }
        }
    }, [resultatEval]);

    useEffect(() => {
        //console.log(totalBareme);
        setValue("totalBaremeSousCritere", totalBareme)
    }, [totalBareme])

    useEffect(() => {
        //console.log(totalEval);
        setValue("totalEval", totalEval)
    }, [totalEval])


    useEffect(() => {
        //mettre à jour les champs totalBareme
        if (souscriteres.length > 0) {
            try {
                let total = 0
                souscriteres.map(souscritere => {
                    total += parseInt(souscritere.point_souscritere)
                })
                setTotalBareme(total)
            } catch (error) {
                console.warn(error.message);
            }
        }
    }, [souscriteres]);

    const getResultat_Evaluation = (id) => {
        if (id) {
            ResultatEvaluation.getEvaluationResult(id)
                .then(data => {
                    if (data) {
                        setResultatEval(data)
                    }
                })
        }
    }

    /*const exporterEvaluation = async () => {
        const fileBuffer = await HTMLtoDOCX(htmlString, null, {
            table: { row: { cantSplit: false } },
            footer: true,
            pageNumber: true,
        });

        saveAs(fileBuffer, 'html-to-docx.docx');

    }*/

    const exporterEvaluation = async () => {
        const img = await fetch(image_entete).then(r => r.blob())
        let txt = "Evaluation de performance de "
        if (agent) {
            txt += agent.prenom.charAt(0).toUpperCase() + agent.prenom.slice(1) + " datant du " + formatDate(new Date(resultatEval[0].date_evaluation))
        }
        //critères: criteres; souscriteres = souscriteres, resultatevaluations
        const resulats_evaluations = await getResultats(resultatEval[0].id_resultateval)
            .then(data => {
                return data
            })
        /*console.log(criteres);
        console.log(souscriteres);
        console.log(resulats_evaluations);*/
        const signatures = new docx.Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [new Paragraph("Le Coordonnateur Général                                              ")],
                            borders: {
                                top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 45,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [new Paragraph("Le Responsable Administratif et Financier                                  ")],
                            borders: {
                                top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 20,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [new Paragraph("L'évaluateur")],
                            borders: {
                                top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                                right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 35,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                    ],
                })
            ],
            width: {
                size: 100,
                type: WidthType.AUTO,
            },
            //columnWidths: [convertInchesToTwip(0.3), convertInchesToTwip(0.5), convertInchesToTwip(0.2)],
        });

        const documentCreator = new DocumentCreator()
        const doc = new Document({
            sections: [
                {
                    children: [
                        new Paragraph({
                            children: [
                                new ImageRun({
                                    data: img,
                                    transformation: {
                                        width: 530,
                                        height: 219
                                    }
                                })
                            ]
                        }),
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: txt,
                                    bold: true,
                                })
                            ]
                        }),
                        documentCreator.create(criteres, souscriteres, resulats_evaluations, resultatEval[0]),
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: "Le Coordonnateur Général                  Le Responsable Administratif et Financier                 L'évaluateur",
                                })
                            ]
                        }),
                        //signatures

                        //table5
                        //table_static
                    ]
                }
            ]
        });


        Packer.toBlob(doc).then(blob => {
            //console.log(blob);
            saveAs(blob, "evaluation.docx");
            //console.log("Document created successfully");
        });
    }
    //console.log(agent);
    return (
        <>
            <Header />
            <Sidebar />
            <div className="page-wrapper">
                <Container fluid className="content">
                    <Row>
                        {(resultatEval.length > 0 && viewer) && (viewerInfo.niveau > 1) ? (
                            <>
                                <Col sm={12}>
                                    <PageHeader title={"Formulaire d'Evaluation de performance n°" + id} />
                                </Col>
                                <section className="review-section personal-excellence">
                                    <div className="review-header text-center">
                                        <h3 className="review-title">Résultat de l'Evaluation de performance {agent && (<span style={{ color: "#ff9b44" }}>&laquo;{agent.prenom.charAt(0).toUpperCase() + agent.prenom.slice(1)}&raquo;</span>)} le {resultatEval[0].date_evaluation && formatDate(new Date(resultatEval[0].date_evaluation))}</h3>
                                        {/*<p className="text-muted">Lorem ipsum dollar</p>*/}
                                    </div>
                                    <FormProvider {...methods} >
                                        <Form>
                                            <Form.Group controlId="formEvaluation">
                                                <Table
                                                    responsive
                                                    bordered
                                                    className='review-table mb-0'
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: "40px" }}>#</th>
                                                            <th>Critère à évaluer</th>
                                                            <th>Indicateurs</th>
                                                            <th>Note</th>
                                                            <th>Note attribuée</th>
                                                            <th>Observation</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            (criteres && criteres.length > 0) && (
                                                                criteres.map(item => <CritereItem critere={item} key={item.id_critere} resultatEvaluation={resultatEval} />)
                                                            )
                                                        }
                                                        <tr>
                                                            <td colSpan="3" className="text-center">Note de performance totale <br />
                                                                avec Observation générale</td>
                                                            <td><Form.Group className="mb-3" controlId="totalBaremeSousCritere">
                                                                <Form.Control type="number" {...register("totalBaremeSousCritere")} readOnly />
                                                            </Form.Group></td>
                                                            <td><Form.Group className="mb-3" controlId="totalEval">
                                                                <Form.Control type="number" value={resultatEval[0].total_note ? resultatEval[0].total_note : 0} readOnly />
                                                            </Form.Group></td>
                                                            <td><Form.Group className="mb-3" controlId="observationGenerale">
                                                                <Form.Control
                                                                    type="text"
                                                                    value={resultatEval[0].observation_generale ? resultatEval[0].observation_generale : ""} readOnly />
                                                            </Form.Group></td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="6" className="text-center">
                                                                <div className="grade-span">
                                                                    <h4>Notation</h4>
                                                                    <span className="badge bg-inverse-danger">Inférieur à 65 Insuffisant</span>
                                                                    <span className="badge bg-inverse-warning">65-74 Moyen</span>
                                                                    <span className="badge bg-inverse-info">75-84 Satisfaisant</span>
                                                                    <span className="badge bg-inverse-purple">85-92 Bon</span>
                                                                    <span className="badge bg-inverse-success">Supérieur à 92 Excellent</span>
                                                                </div>
                                                                {<Button variant="primary" onClick={exporterEvaluation} className="submit-btn mt-3">
                                                                    Imprimer les résultats
                                                                </Button>}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Form.Group>
                                        </Form>
                                    </FormProvider>
                                </section>
                            </>)
                            : (<Alerte variant="danger" message="Vous ne pouvez pas visualiser cette évaluation." />)}
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default ViewEvaluation