import axios from 'axios';
import { API_baseUrl } from './config';

//const API_url = "http://localhost:8082/api/souscriteres/"
const API_url = API_baseUrl + "/souscriteres/"
// get liste des souscriteres
const getSousCriteresByCritere = (id) => {
    return axios.get(API_url + id)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            return error.response.data
        });
}

// get liste des souscriteres
const getSousCriteres = () => {
    return axios.get(API_url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            return error.response.data
        });
}

const exports = {
    getSousCriteresByCritere,
    getSousCriteres
}

export default exports