import SouscritereService from '../services/souscritere.service';
import EvaluationService from '../services/evaluation.service'
import image_entete from '../assets/img/entete.JPG';

const docx = require('docx');

const { Document,
    ImageRun,
    Media,
    Table, TableCell, TableRow, WidthType, SectionType,
    Packer,
    Paragraph,
    HeadingLevel,
    AlignmentType,
    TextRun } = docx;

export class DocumentCreator {
    create = (criteres, souscriteres, resulats_evaluations, resultateval) => { // retourne tableau contenant critères,souscritères,resultats evaluations
        /*console.log(criteres);
        console.log(souscriteres);
        console.log(resulats_evaluations);*/
        console.log(resultateval)
        let total = 0
        souscriteres.map(souscritere => {
            total += parseInt(souscritere.point_souscritere)
        })
        console.log(total)

        const tableEvaluation = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: "Critère à évaluer",
                                            bold: true,
                                            font: {
                                                name: "Monospace",
                                            },
                                        }),
                                    ]
                                })
                            ],

                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: "Indicateurs",
                                            bold: true,
                                            font: {
                                                name: "Monospace",
                                            },
                                        }),
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: "Barème",
                                            bold: true,
                                            font: {
                                                name: "Monospace",
                                            },
                                        }),
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: "Note attribuée",
                                            bold: true,
                                            font: {
                                                name: "Monospace",
                                            },
                                        }),
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: "Observation",
                                            bold: true,
                                            font: {
                                                name: "Monospace",
                                            },
                                        }),
                                    ]
                                })
                            ]
                        })
                    ],
                }),
            ],
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
        });

        let tmp_idcritere = 1
        for (let i = 0; i < souscriteres.length; i++) {
            let resulatFiltered = resulats_evaluations.filter(res => res.id_souscritere == souscriteres[i].id_souscritere)
            let aboutCritere = criteres.filter(critere => critere.id_critere == souscriteres[i].id_critere)
            let nbSouscriteres = souscriteres.filter(res => res.id_critere == aboutCritere[0].id_critere).length
            if (tmp_idcritere == souscriteres[i].id_souscritere) {//si:1ère ligne 5 cellules dont 1ère cellule avec rowspan nombre de souscriteres
                tmp_idcritere = souscriteres[i].id_souscritere + nbSouscriteres
                tableEvaluation.root.push(new TableRow({
                    children: [
                        new TableCell({
                            children: [new Paragraph({ text: aboutCritere[0].libelle_critere, alignment: AlignmentType.CENTER })],
                            rowSpan: nbSouscriteres,
                            verticalMerge: docx.VerticalMergeType.RESTART
                        }),
                        new TableCell({
                            children: [new Paragraph(souscriteres[i].libelle_souscritere ? souscriteres[i].libelle_souscritere : "")],
                        }),
                        new TableCell({
                            children: [new Paragraph(souscriteres[i].point_souscritere ? souscriteres[i].point_souscritere : "")],
                        }),
                        new TableCell({
                            children: [new Paragraph(resulatFiltered[0].point_obtenu ? resulatFiltered[0].point_obtenu : "")],
                        }),
                        new TableCell({
                            children: [new Paragraph(resulatFiltered[0].observation_notation ? resulatFiltered[0].observation_notation : "")],
                        })
                    ],
                }))
            } else {
                tableEvaluation.root.push(new TableRow({
                    children: [
                        new TableCell({
                            children: [new Paragraph("")],
                            verticalMerge: docx.VerticalMergeType.CONTINUE
                        }),
                        new TableCell({
                            children: [new Paragraph(souscriteres[i].libelle_souscritere ? souscriteres[i].libelle_souscritere : "")],
                        }),
                        new TableCell({
                            children: [new Paragraph(souscriteres[i].point_souscritere ? souscriteres[i].point_souscritere : "")],
                        }),
                        new TableCell({
                            children: [new Paragraph(resulatFiltered[0].point_obtenu ? resulatFiltered[0].point_obtenu : "")],
                        }),
                        new TableCell({
                            children: [new Paragraph(resulatFiltered[0].observation_notation ? resulatFiltered[0].observation_notation : "")],
                        })
                    ],
                }))
            }
        }
        tableEvaluation.root.push(new TableRow({
            children: [
                new TableCell({
                    children: [new Paragraph("Note de performance totale avec Observation générale")],
                    columnSpan: 2
                }),
                new TableCell({
                    children: [new Paragraph(""+total)],
                }),
                new TableCell({
                    children: [new Paragraph(resultateval.total_note)],
                }),
                new TableCell({
                    children: [new Paragraph(resultateval.observation_generale)],
                })
            ],
        }))

        return tableEvaluation
    }
}