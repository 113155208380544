import React from 'react'
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { SplitButton, Dropdown } from 'react-bootstrap';

import AuthService from "../services/auth.service";
import Avatar from './Avatar';
import UserInfo from './UserInfo';

const EmployeItem = (props) => {
    const user = AuthService.getCurrentUser();
    //console.log(props.personnel);
    //console.log(user);
    return (
        <>
            {props.personnel && (<Col md={4} sm={6} lg={4} xl={3}>
                <div className="profile-widget">
                    <div className="profile-img">
                        <Link to={`/profile/${props.personnel.id_agent}`} className="avatar">{props.personnel.avatar && (<Avatar url={props.personnel.avatar} />)}</Link>
                    </div>

                    {((user.id_agent == props.personnel.superieur_id || user.id_agent == 2) && user.id_agent != props.personnel.id_agent) && (
                        <>
                            <Dropdown className="profile-action">
                                <Dropdown.Toggle variant="" id="dropdown-basic" className="action-icon">
                                <i class="material-icons">more_vert</i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href={`/evaluation/${props.personnel.id_agent}`}>Evaluer</Dropdown.Item>
                                    <Dropdown.Item href={`/profile/${props.personnel.id_agent}`}>Voir son profil</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </>
                    )}
                    <UserInfo personnel={props.personnel} />
                </div>
            </Col>)}
        </>
    )
}

export default EmployeItem